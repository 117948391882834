/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .product_imgs {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.page_content .product_imgs .product_img {
  position: relative;
  margin: 0 20px;
}

.page_content .product_imgs .product_img img {
  width: 160px;
  height: 160px;
  border-radius: 8px;
}

.page_content .product_imgs .product_img .trash_icon {
  width: 40px;
  height: 40px;
  background: var(--blue);
  border: 2px solid var(--white);
  border-radius: 50%;
  position: absolute;
  bottom: -10px;
  right: -10px;
  font-size: 24px;
  color: var(--white);
  text-align: center;
  line-height: 44px;
}

.page_content .product_imgs .product_img .trash_icon:hover {
  background: var(--red);
}

.page_content .product_imgs .product_img input {
  display: none;
}

.page_content .product_imgs .product_img .img_add {
  width: 160px;
  height: 160px;
  border-radius: 8px;
  background: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_content .product_imgs .product_img .img_add:hover {
  cursor: pointer;
}

.page_content .product_imgs .product_img .img_add .img_add_icon {
  width: 50px;
  height: 50px;
  font-size: 36px;
  border-radius: 50%;
  color: var(--white);
  background: var(--blue);
  text-align: center;
  line-height: 62px;
}

.page_content .product_imgs .product_img .img_add:hover .img_add_icon {
  background: var(--red);
}

.page_content .product_details {
  min-width: 840px;
  /* width: 100%; */
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
}

.page_content .product_details .product_more_details {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.page_content .product_details .product_description {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}

.page_content .product_details .text_input_container {
  position: relative;
  min-width: 400px;
  width: 100%;
  /* max-width: 500px; */
  padding: 12px 0 24px;
  margin: 0;
}

.page_content .product_details .text_input_container .text_input_title {
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 10px;
  padding: 2px 4px;
  background: var(--register-page-text-input-background);
  color: var(--register-page-text-input-label-color);
}

.page_content .product_details .text_input_container .text_input_content {
  height: 40px;
  width: 100%;
  border: 2px solid var(--register-page-text-input-border-color);
  padding: 0 10px 0 10px;
  border-radius: 8px;
}

.page_content .product_details .text_input_line {
  display: flex;
  justify-content: space-between;
  min-width: 400px;
  width: 100%;
  /* max-width: 500px; */
}

.page_content
  .product_details
  .text_input_line
  .text_input_container
  + .text_input_container {
  margin-left: 20px;
}

.page_content .product_buttons {
  min-width: 840px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.page_content .product_buttons .button1,
.page_content .product_buttons .button2 {
  margin-top: 40px;
  min-width: 400px;
  width: 50%;
  /* max-width: 450px; */
}

.page_content .product_buttons .button1 {
  margin-right: 20px;
}

.page_content .product_buttons .button2 {
  margin-left: 20px;
}
