/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .profile_details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0 20px;
}

.page_content .profile_details .profile_data_container {
  width: 40%;
}

.page_content .profile_details .profile_data_container .profile_data {
  position: relative;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 13px 5px;
}

.page_content .profile_details .profile_data_container .profile_data .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page_content .profile_details .profile_data_container .profile_data svg {
  height: 20px;
  width: 20px;
  color: var(--blue);
  margin-right: 5px;
}

.page_content
  .profile_details
  .profile_data_container
  .profile_data
  .data_edit_icon_button {
  position: absolute;
  top: 25px;
  right: 0px;
  font-size: 20px;
  color: var(--blue);
}

.data_edit_icon_button:hover {
  filter: brightness(90%);
}

.page_content .profile_details .profile_data_container .profile_data h2 {
  font-size: 20px;
  font-weight: bold;
}

.page_content .profile_details .profile_data_container .profile_data p {
  font-size: 18px;
  color: var(--black);
  padding: 5px 0 0 25px;
  width: 95%;
}

.page_content .profile_details .img_container {
  position: relative;
  width: 300px;
  height: 300px;
}

.page_content .profile_details .img_container img {
  width: 300px;
  height: 300px;
  border-radius: 40px;
}

.page_content .profile_details .img_container input {
  display: none;
}

.edit_icon_container {
  position: absolute;
  bottom: 0px;
  right: -10px;
  height: 40px;
  width: 40px;
  background: var(--blue);
  border: 2px solid var(--white);
  color: var(--white);
  font-size: 24px;
  text-align: center;
  padding: 6px 0;
  border-radius: 100px;
  cursor: pointer;
}

.edit_icon_container:hover {
  background: var(--red);
}
