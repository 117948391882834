/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_content .head h1 {
  margin: 0;
  margin-right: 5px;
}

.page_content .store_details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0;
}

.page_content .store_details .store_data_container .store_data {
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 12px 5px;
}

.page_content .store_details .store_data_container .store_data .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page_content .store_details .store_data_container .store_data svg {
  height: 20px;
  width: 20px;
  color: var(--blue);
  margin-right: 5px;
}

.page_content .store_details .store_data_container .store_data h2 {
  font-size: 20px;
  font-weight: bold;
}

.page_content .store_details .store_data_container .store_data p {
  font-size: 18px;
  padding: 5px 0 0 25px;
  color: var(--black);
}

.page_content .store_details .store_data_container .button1 {
  width: 100%;
  margin-top: 80px;
}

.page_content .store_details .img_container img {
  width: 340px;
  height: 340px;
  border-radius: 12px;
}
