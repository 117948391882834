@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

.admin_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 80px;
  background: var(--sidebar-background);
  padding: 5px 15px;
  transition: all 0.5s ease;
}
.admin_sidebar.active {
  width: 300px;
}

.admin_sidebar .head {
  height: 130px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.admin_sidebar.active .head {
  display: flex;
  flex-direction: row;
}

.admin_sidebar .head .logo {
  margin-top: 10px;
  display: flex;
  height: 50%;
  width: auto;
  pointer-events: none;
  transition: all 0.5s ease;
}
.admin_sidebar.active .head .logo {
  margin-top: 0;
  height: 100%;
  pointer-events: none;
}

.admin_sidebar .head .logo img {
  height: auto;
  width: 100%;
}
.admin_sidebar.active .head .logo img {
  height: 100%;
  width: auto;
}

.admin_sidebar .head .menu {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.admin_sidebar.active .head .menu {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.admin_sidebar #menu_icon {
  color: var(--sidebar-color);
  font-size: 24px;
  height: 50px;
  width: 50px;
  text-align: center;
  padding: 13px 0;
  border-radius: 12px;
}
.admin_sidebar #menu_icon:hover {
  color: var(--sidebar-hover-color);
  background: var(--sidebar-hover-background);
}

.admin_sidebar ul {
  margin-top: 40px;
}

.admin_sidebar ul li {
  position: relative;
  height: 50px;
  width: 100%;
  margin: 10px 0px;
  list-style: none;
}

.caption {
  position: absolute;
  left: calc(75px + 140px / 2);
  top: 0;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  height: 30px;
  line-height: 30px;
  width: 140px;
  background: var(--sidebar-caption-background);
  color: var(--sidebar-caption-color);
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0s;
  opacity: 0;
  pointer-events: none;
  display: block;
}
.admin_sidebar.active .caption {
  display: none;
}
.admin_sidebar ul li:hover .caption {
  transition: all 0.5s ease;
  opacity: 1;
  top: 50%;
}

.admin_sidebar #menu_link {
  margin-bottom: 50px;
}

.admin_sidebar .nav_link {
  color: var(--sidebar-color);
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
  font-size: 16px;
}
.admin_sidebar.active .nav_link {
  width: 100%;
}
.admin_sidebar .nav_link:hover {
  background: var(--sidebar-hover-background);
  color: var(--sidebar-hover-color);
}

.admin_sidebar .sidebar_icon {
  height: 50px;
  min-width: 50px;
  border-radius: 12px;
  font-size: 24px;
  padding: 13px 0;
  text-align: center;
}

.admin_sidebar .link_title {
  opacity: 0;
  pointer-events: none;
  transition: all 0.1s ease;
}
.admin_sidebar.active .link_title {
  opacity: 1;
  pointer-events: auto;
}

.admin_sidebar .logout_button_container {
  position: absolute;
  bottom: 5px;
  height: 50px;
  width: calc(100% - 30px);
  margin: 10px 0px;
}

.admin_sidebar .logout_button {
  color: var(--sidebar-color);
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
}
.admin_sidebar.active .logout_button {
  width: 100%;
}
.admin_sidebar .logout_button:hover {
  background: var(--sidebar-hover-background);
  color: var(--sidebar-hover-color);
}

.admin_sidebar .logout_button_container:hover .caption {
  transition: all 0.5s ease;
  opacity: 1;
  top: 50%;
}

.admin_sidebar ul li .current_page {
  color: var(--sidebar-current-page-color);
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
  /* pointer-events: none; */
}
.admin_sidebar.active ul li .current_page {
  width: 100%;
  display: flex;
  align-items: center;
}
.admin_sidebar.active ul li .current_page i,
.admin_sidebar ul li .current_page i {
  font-size: 24px;
}
.admin_sidebar ul li .current_page .link_title,
.admin_sidebar.active ul li .current_page .link_title {
  pointer-events: none;
  font-size: 16px;
  font-weight: bold;
}
