/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .head .search_bar {
  height: 40px;
  max-width: 1000px;
  width: 60%;
  min-width: 250px;
  border-radius: 8px;
  /* background: var(--light-gray); */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 5px;
  border: 2px solid var(--blue);
}

.page_content .head .search_bar input {
  border: none;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  /* background: var(--light-gray); */
  padding-left: 20px;
  padding-right: 5px;
  font-size: 14px;
}

.page_content .head .search_bar button {
  height: 100%;
  margin: 0 5px;
  line-height: 46px;
}

.page_content .head .search_bar button svg {
  font-size: 20px;
  color: var(--blue);
}

.page_content .head .search_bar button:hover svg {
  color: var(--red);
}

.page_content .product_list {
  width: 100%;
  /* margin-top: 80px; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  grid-gap: 30px;
}

.page_content .product_list .product_data {
  justify-self: center;
}

.page_content .product_list .product_data_content {
  width: 300px;
  margin: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid transparent;
}

.page_content .product_list .product_data_content:hover {
  /* background: #fefefe; */
  /* filter: brightness(90%); */
  border: 2px solid var(--red);
}

.page_content .product_list .product_data_content img {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  margin-right: 0px;
}

.page_content .product_list .product_data_content .text {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.page_content .product_list .product_data_content .text div {
  height: auto;
  margin-bottom: 5px;
}

.page_content .product_list .product_data_content .text div h2 {
  font-size: 14px;
  font-weight: bold;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  max-height: 48px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.page_content .product_list .product_data_content .text h3 {
  font-size: 30px;
  font-weight: bold;
  width: 140px;
}

.page_content .product_list .product_data_content .text p {
  font-size: 12px;
  /* margin-top: 5px; */
  font-weight: bold;
  width: 100px;
}

.page_content .product_list .product_data_content .stars {
  height: 100px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .product_list .product_data_content .stars svg {
  font-size: 18px;
  color: var(--gray);
}

.page_content .product_list .product_data_content .stars .colorful {
  color: gold;
}

h2,
p {
  color: var(--blue);
}

h3 {
  color: var(--red);
}
