@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
* {
  --green: #0d7356;
  --blue: #052440;
  --red: #bf1717;
  --orange: #f27830;
  --beige: #f2e0d0;
  --gray: #a7a7a7;
  --light-gray: #ccc;
  --white: #fff;
  --black: #000;

  --sidebar-background: var(--blue);
  --sidebar-color: var(--white);
  --sidebar-hover-background: var(--red);
  --sidebar-hover-color: var(--white);
  --sidebar-search-background: var(--white);
  --sidebar-search-color: var(--blue);
  --sidebar-active-search-btn-hover-color: var(--red);
  --sidebar-caption-background: var(--red);
  --sidebar-caption-color: var(--white);
  --sidebar-current-page-color: var(--red);

  --register-page-background: var(--blue);
  --register-page-form-background: var(--white);
  --register-page-form-border-color: var(--red);
  --register-page-form-title-color: var(--red);
  --register-page-text-input-background: var(--white);
  --register-page-text-input-label-color: var(--blue);
  --register-page-text-input-border-color: var(--blue);

  --button1-background: var(--red);
  --button1-color: var(--white);

  --button2-background: var(--blue);
  --button2-color: var(--white);

  --button3-background: var(--black);
  --button3-color: var(--blue);
  --button3-color-hover: var(--red);

  --text-info-background-color: var(--white);
  --text-info-label-color: var(--red);
  --text-info-border-color: var(--blue);

  --edit-icon-color: var(--red);
  --caret-down-icon-color: var(--red);

  --search-input-background: var(--light-gray);

  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

body {
  font: 400 14px Roboto, sans-serif;
  background-color: var(--white);
  -webkit-font-smoothing: antialiased;
}

input,
button,
textarea {
  font: 400 18px Roboto, sans-serif;
}

button {
  background: none;
  cursor: pointer;
  border: none;
}

.button1 {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 12px;
  background: var(--button1-background);
  color: var(--button1-color);
  font-size: 18px;
  text-align: center;
  padding: 10px;
}
.button1:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.button2 {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 12px;
  background: var(--button2-background);
  color: var(--button2-color);
  font-size: 18px;
  text-align: center;
  padding: 10px;
}
.button2:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.button3 {
  margin-top: 20px;
  width: 100%;
  /* height: 40px; */
  border: none;
  /* border-radius: 12px; */
  /* background: var(--button3-background); */
  color: var(--button3-color);
  font-size: 18px;
  text-align: center;
  padding: 10px;
  font-weight: bold;
}
.button3:hover {
  color: var(--button3-color-hover);
}

.page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 0 80px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.page_content .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.page_content .head h1 {
  color: var(--red);
}

.page_content .head img {
  height: 100px;
  width: auto;
  margin-left: 20px;
}

.circle_button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 100px;
  height: 48px;
  width: 48px;
  background: var(--red);
  padding: 6px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 1);
}

.circle_button:hover {
  /* filter: brightness(80%); */
}
.circle_button:hover .caption {
  /* filter: brightness(125%); */
}

.circle_button svg {
  height: 36px;
  width: 36px;
  color: var(--white);
  text-align: center;
}

.circle_button .caption {
  position: absolute;
  left: -275px;
  bottom: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 6px;
  height: 30px;
  line-height: 30px;
  width: 250px;
  background: var(--sidebar-caption-background);
  color: var(--sidebar-caption-color);
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0s;
  opacity: 0;
  pointer-events: none;
  display: block;
}
.circle_button:hover .caption {
  transition: all 0.5s ease;
  opacity: 1;
  top: 50%;
}

.content {
  position: relative;
  min-width: 760px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--register-page-background);
}

.logo_container {
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: 40px;
}

.logo_container img {
  min-width: 200px;
  width: 70%;
  max-width: 300px;
  height: auto;
}

.logo_container img + img {
  margin-top: 0px;
}

.form_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  min-height: 600px;
  width: 100%;
  min-width: 600px;
  max-width: 1200px;
  background: var(--register-page-form-background);
  padding: 20px 6% 40px;
  /* border: 2px solid var(--register-page-form-border-color); */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  border-radius: 12px;
}

.form_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form_content h1 {
  margin: 10px 0 20px;
  color: var(--register-page-form-title-color);
}

.text_input_line {
  display: flex;
  justify-content: space-between;
  min-width: 300px;
  width: 100%;
  max-width: 600px;
}

.text_input_container {
  position: relative;
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  padding: 12px 0 24px;
  margin: 0;
}

.text_input_container .text_input_title {
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 10px;
  padding: 2px 4px;
  background: var(--register-page-text-input-background);
  color: var(--register-page-text-input-label-color);
}

.text_input_container .text_input_content {
  height: 40px;
  width: 100%;
  border: 2px solid var(--register-page-text-input-border-color);
  padding: 0 35px 0 10px;
  border-radius: 8px;
}

.button1,
.button2 {
  min-width: 300px;
  width: 100%;
  max-width: 600px;
}

.forget_password {
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: flex-start;
  padding-left: 10px;
}

.forget_password span {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: var(--red);
}

.checkbox_container {
  height: 40px;
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  padding: 12px 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.checkbox_container input[type="checkbox"] {
  display: none;
}
.checkbox_container label {
  cursor: pointer;
}

.checkbox_container input[type="checkbox"] + label:before {
  border: 1px solid var(--blue);
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 10px 0 5px;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 4px;
}

.checkbox_container input[type="checkbox"]:checked + label:before {
  background: var(--red);
  color: #fff;
  content: "\2713";
  text-align: center;
}

.checkbox_container input[type="checkbox"]:checked + label:after {
  font-weight: bold;
}

.checkbox_container a {
  font-weight: bold;
  color: var(--red);
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .profile_details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0 20px;
}

.page_content .profile_details .profile_data_container {
  width: 40%;
}

.page_content .profile_details .profile_data_container .profile_data {
  position: relative;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 13px 5px;
}

.page_content .profile_details .profile_data_container .profile_data .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page_content .profile_details .profile_data_container .profile_data svg {
  height: 20px;
  width: 20px;
  color: var(--blue);
  margin-right: 5px;
}

.page_content
  .profile_details
  .profile_data_container
  .profile_data
  .data_edit_icon_button {
  position: absolute;
  top: 25px;
  right: 0px;
  font-size: 20px;
  color: var(--blue);
}

.data_edit_icon_button:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.page_content
  .profile_details
  .profile_data_container
  .profile_data
  .adress_edit_icon_button {
  position: absolute;
  top: 16px;
  right: 0px;
  font-size: 20px;
  color: var(--blue);
}

.adress_edit_icon_button:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.page_content
  .profile_details
  .profile_data_container
  .profile_data
  .adress_delete_icon_button {
  position: absolute;
  top: 41px;
  right: 0px;
  font-size: 20px;
  color: var(--blue);
}

.adress_delete_icon_button:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.page_content .profile_details .profile_data_container .profile_data h2 {
  font-size: 20px;
  font-weight: bold;
}

.page_content .profile_details .profile_data_container .profile_data p {
  font-size: 18px;
  color: var(--black);
  padding: 5px 0 0 25px;
  width: 95%;
}

.page_content .profile_details .profile_data_container .fields_title {
  margin-top: 40px;
  font-size: 18px;
  color: var(--red);
  margin-bottom: 20px;
}

.page_content .profile_details .img_container {
  position: relative;
  width: 300px;
  height: 300px;
}

.page_content .profile_details .img_container img {
  width: 300px;
  height: 300px;
  border-radius: 40px;
}

.page_content .profile_details .img_container input {
  display: none;
}

.edit_icon_container {
  position: absolute;
  bottom: 0px;
  right: -10px;
  height: 40px;
  width: 40px;
  background: var(--blue);
  border: 2px solid var(--white);
  color: var(--white);
  font-size: 24px;
  text-align: center;
  padding: 6px 0;
  border-radius: 100px;
  cursor: pointer;
}

.edit_icon_container:hover {
  background: var(--red);
}

.page_content .profile_details .profile_data_container .button1 {
  margin-top: 20px;
  width: 100%;
  min-width: 400px;
  max-width: 1000px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 80px;
  background: var(--sidebar-background);
  padding: 5px 15px;
  transition: all 0.5s ease;
}
.sidebar.active {
  width: 300px;
}

.sidebar .head {
  height: 130px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.sidebar.active .head {
  display: flex;
  flex-direction: row;
}

.sidebar .head .logo {
  margin-top: 10px;
  display: flex;
  height: 50%;
  width: auto;
  pointer-events: none;
  transition: all 0.5s ease;
}
.sidebar.active .head .logo {
  margin-top: 0;
  height: 100%;
  pointer-events: none;
}

.sidebar .head .logo img {
  height: auto;
  width: 100%;
}
.sidebar.active .head .logo img {
  height: 100%;
  width: auto;
}

.sidebar .head .menu {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.sidebar.active .head .menu {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.sidebar #menu_icon {
  color: var(--sidebar-color);
  font-size: 24px;
  height: 50px;
  width: 50px;
  text-align: center;
  padding: 13px 0;
  border-radius: 12px;
}
.sidebar #menu_icon:hover {
  color: var(--sidebar-hover-color);
  background: var(--sidebar-hover-background);
}

.sidebar ul {
  margin-top: 40px;
}

.sidebar ul li {
  position: relative;
  height: 50px;
  width: 100%;
  margin: 10px 0px;
  list-style: none;
}

.caption {
  position: absolute;
  left: calc(75px + 140px / 2);
  top: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 6px;
  height: 30px;
  line-height: 30px;
  width: 140px;
  background: var(--sidebar-caption-background);
  color: var(--sidebar-caption-color);
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0s;
  opacity: 0;
  pointer-events: none;
  display: block;
}
.sidebar.active .caption {
  display: none;
}
.sidebar ul li:hover .caption {
  transition: all 0.5s ease;
  opacity: 1;
  top: 50%;
}

.sidebar #menu_link {
  margin-bottom: 50px;
}

.sidebar .nav_link {
  color: var(--sidebar-color);
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
  font-size: 16px;
}
.sidebar.active .nav_link {
  width: 100%;
}
.sidebar .nav_link:hover {
  background: var(--sidebar-hover-background);
  color: var(--sidebar-hover-color);
}

.sidebar .sidebar_icon {
  height: 50px;
  min-width: 50px;
  border-radius: 12px;
  font-size: 24px;
  padding: 13px 0;
  text-align: center;
}

.sidebar .link_title {
  opacity: 0;
  pointer-events: none;
  transition: all 0.1s ease;
}
.sidebar.active .link_title {
  opacity: 1;
  pointer-events: auto;
}

.sidebar .logout_button_container {
  position: absolute;
  bottom: 5px;
  height: 50px;
  width: calc(100% - 30px);
  margin: 10px 0px;
}

.sidebar .logout_button {
  color: var(--sidebar-color);
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
}
.sidebar.active .logout_button {
  width: 100%;
}
.sidebar .logout_button:hover {
  background: var(--sidebar-hover-background);
  color: var(--sidebar-hover-color);
}

.sidebar .logout_button_container:hover .caption {
  transition: all 0.5s ease;
  opacity: 1;
  top: 50%;
}

.sidebar ul li .current_page {
  color: var(--sidebar-current-page-color);
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
  /* pointer-events: none; */
}
.sidebar.active ul li .current_page {
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar.active ul li .current_page i,
.sidebar ul li .current_page i {
  font-size: 24px;
}
.sidebar ul li .current_page .link_title,
.sidebar.active ul li .current_page .link_title {
  pointer-events: none;
  font-size: 16px;
  font-weight: bold;
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .profile_details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0 20px;
}

.page_content .profile_details .profile_data_container {
  width: 40%;
}

.page_content .profile_details .profile_data_container .profile_data {
  position: relative;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 13px 5px;
}

.page_content .profile_details .profile_data_container .profile_data .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page_content .profile_details .profile_data_container .profile_data svg {
  height: 20px;
  width: 20px;
  color: var(--blue);
  margin-right: 5px;
}

.page_content
  .profile_details
  .profile_data_container
  .profile_data
  .data_edit_icon_button {
  position: absolute;
  top: 25px;
  right: 0px;
  font-size: 20px;
  color: var(--blue);
}

.data_edit_icon_button:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.page_content
  .profile_details
  .profile_data_container
  .profile_data
  .adress_edit_icon_button {
  position: absolute;
  top: 16px;
  right: 0px;
  font-size: 20px;
  color: var(--blue);
}

.adress_edit_icon_button:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.page_content
  .profile_details
  .profile_data_container
  .profile_data
  .adress_delete_icon_button {
  position: absolute;
  top: 41px;
  right: 0px;
  font-size: 20px;
  color: var(--blue);
}

.adress_delete_icon_button:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.page_content .profile_details .profile_data_container .profile_data h2 {
  font-size: 20px;
  font-weight: bold;
}

.page_content .profile_details .profile_data_container .profile_data p {
  font-size: 18px;
  color: var(--black);
  padding: 5px 0 0 25px;
  width: 95%;
}

.page_content .profile_details .profile_data_container .fields_title {
  margin-top: 40px;
  font-size: 18px;
  color: var(--red);
  margin-bottom: 20px;
}

.page_content .profile_details .img_container {
  position: relative;
  width: 300px;
  height: 300px;
}

.page_content .profile_details .img_container img {
  width: 300px;
  height: 300px;
  border-radius: 40px;
}

.page_content .profile_details .img_container input {
  display: none;
}

.edit_icon_container {
  position: absolute;
  bottom: 0px;
  right: -10px;
  height: 40px;
  width: 40px;
  background: var(--blue);
  border: 2px solid var(--white);
  color: var(--white);
  font-size: 24px;
  text-align: center;
  padding: 6px 0;
  border-radius: 100px;
  cursor: pointer;
}

.edit_icon_container:hover {
  background: var(--red);
}

.page_content .profile_details .profile_data_container .button1 {
  margin-top: 20px;
  width: 100%;
  min-width: 400px;
  max-width: 1000px;
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .info_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40 40px;
  width: 90%;
  min-width: 240px;
  max-width: 720px;
  background: var(--register-page-form-background);
  border-radius: 12px;
  display: block;
}

h1 {
  margin: 20px 0 20px;
  color: var(--register-page-form-title-color);
}

.page_content .info_container.active {
  display: none;
}

.page_content .info_container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40 40px;
  width: 90%;
  min-width: 240px;
  max-width: 720px;
  background: var(--register-page-form-background);
  border-radius: 12px;
  display: block;
}

.edit_button {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.edit_icon {
  height: 20px;
  width: 20px;
  color: var(--edit-icon-color);
}
.edit_icon:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.caret_down_button {
}

.caret_down_icon {
  height: 20px;
  width: 20px;
  color: var(--caret-down-icon-color);
}
.caret_down_icon:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.adress_info_container .adress_list {
  display: block;
  transition: all 0.5s ease;
}
.adress_info_container .adress_list.active {
  display: none;
}
.adress_info_container .adress_list .adress_item {
  margin: 10px 0 0;
}
.adress_info_container .adress_list .adress_item .adress {
  width: 100%;
  border: none;
  margin: 10px 20px 0;
  padding: 10px 0 0;
}

.button2 {
  margin-top: 40px;
}

.button4 {
  max-width: 280px;
  min-width: 240px;
  margin-bottom: 20px;
}

.photo_container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.photo {
  width: 100%;
  max-width: 100px;
  height: auto;
  max-height: 100px;
  border: 2px solid var(--blue);
  padding: 2px;
  border-radius: 100%;
}

.photo_input {
  display: none;
}

.photo_input_label {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 280px;
  min-width: 240px;
  width: 40%;
  height: 36px;
  border: none;
  border-radius: 12px;
  background: var(--button1-background);
  color: var(--button1-color);
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  line-height: 36px;
}
.photo_input_label:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.form_container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40 40px;
  width: 100%;
  min-width: 240px;
  max-width: 720px;
  background: var(--register-page-form-background);
  border-radius: 12px;
}

.text_input_content {
  height: 55px;
}

.form_container3 {
  margin-top: 20px;
  width: 90%;
  min-width: 240px;
  max-width: 720px;
  display: none;
}

.form_container3.active {
  display: block;
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .info_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40 40px;
  width: 90%;
  min-width: 240px;
  max-width: 720px;
  background: var(--register-page-form-background);
  border-radius: 12px;
  display: block;
}

h1 {
  margin: 20px 0 20px;
  color: var(--register-page-form-title-color);
}

.page_content .info_container.active {
  display: none;
}

.page_content .info_container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40 40px;
  width: 90%;
  min-width: 240px;
  max-width: 720px;
  background: var(--register-page-form-background);
  border-radius: 12px;
  display: block;
}

.edit_button {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.edit_icon {
  height: 20px;
  width: 20px;
  color: var(--edit-icon-color);
}
.edit_icon:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.caret_down_button {
}

.caret_down_icon {
  height: 20px;
  width: 20px;
  color: var(--caret-down-icon-color);
}
.caret_down_icon:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.adress_info_container .adress_list {
  display: block;
  transition: all 0.5s ease;
}
.adress_info_container .adress_list.active {
  display: none;
}
.adress_info_container .adress_list .adress_item {
  margin: 10px 0 0;
}
.adress_info_container .adress_list .adress_item .adress {
  width: 100%;
  border: none;
  margin: 10px 20px 0;
  padding: 10px 0 0;
}

.button2 {
  margin-top: 40px;
}

.button4 {
  max-width: 280px;
  min-width: 240px;
  margin-bottom: 20px;
}

.photo_container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.photo {
  width: 100%;
  max-width: 100px;
  height: auto;
  max-height: 100px;
  border: 2px solid var(--blue);
  padding: 2px;
  border-radius: 100%;
}

.photo_input {
  display: none;
}

.photo_input_label {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 280px;
  min-width: 240px;
  width: 40%;
  height: 36px;
  border: none;
  border-radius: 12px;
  background: var(--button1-background);
  color: var(--button1-color);
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  line-height: 36px;
}
.photo_input_label:hover {
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
}

.form_container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40 40px;
  width: 100%;
  min-width: 240px;
  max-width: 720px;
  background: var(--register-page-form-background);
  border-radius: 12px;
}

.text_input_content {
  height: 55px;
}

.form_container3 {
  margin-top: 20px;
  width: 90%;
  min-width: 240px;
  max-width: 720px;
  display: none;
}

.form_container3.active {
  display: block;
}

.content {
  position: relative;
  min-width: 760px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--register-page-background);
}

.logo_container {
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: 40px;
}

.logo_container img {
  min-width: 200px;
  width: 70%;
  max-width: 300px;
  height: auto;
}

.logo_container img + img {
  margin-top: 250px;
}

.form_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  min-width: 600px;
  max-width: 1200px;
  background: var(--register-page-form-background);
  padding: 20px 6% 40px;
  /* border: 2px solid var(--register-page-form-border-color); */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  border-radius: 12px;
}

.form_content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form_content h1 {
  margin: 10px 0 20px;
  color: var(--register-page-form-title-color);
}

.text_input_line {
  display: flex;
  justify-content: space-between;
  min-width: 300px;
  width: 100%;
  max-width: 600px;
}

.text_input_container {
  position: relative;
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  padding: 12px 0 24px;
  margin: 0;
}

.text_input_container .text_input_title {
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 10px;
  padding: 2px 4px;
  background: var(--register-page-text-input-background);
  color: var(--register-page-text-input-label-color);
}

.text_input_container .text_input_content {
  height: 40px;
  width: 100%;
  border: 2px solid var(--register-page-text-input-border-color);
  padding: 0 35px 0 10px;
  border-radius: 8px;
}

.button1 {
  min-width: 300px;
  width: 100%;
  max-width: 600px;
}

.checkbox_container {
  height: 40px;
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  padding: 12px 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.checkbox_container input[type="checkbox"] {
  display: none;
}
.checkbox_container label {
  cursor: pointer;
}

.checkbox_container input[type="checkbox"] + label:before {
  border: 1px solid var(--blue);
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 10px 0 5px;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 4px;
}

.checkbox_container input[type="checkbox"]:checked + label:before {
  border: 1px solid var(--red);
  background: var(--red);
  color: #fff;
  content: "\2713";
  text-align: center;
}

.checkbox_container input[type="checkbox"]:checked + label:after {
  font-weight: bold;
}

.checkbox_container a {
  font-weight: bold;
  color: var(--red);
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .head .search_bar {
  height: 40px;
  max-width: 1000px;
  width: 60%;
  min-width: 250px;
  border-radius: 8px;
  /* background: var(--light-gray); */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 5px;
  border: 2px solid var(--blue);
}

.page_content .head .search_bar input {
  border: none;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  /* background: var(--light-gray); */
  padding-left: 20px;
  padding-right: 5px;
  font-size: 14px;
}

.page_content .head .search_bar button {
  height: 100%;
  margin: 0 5px;
  line-height: 46px;
}

.page_content .head .search_bar button svg {
  font-size: 20px;
  color: var(--blue);
}

.page_content .head .search_bar button:hover svg {
  color: var(--red);
}

.page_content .product_list {
  width: 100%;
  /* margin-top: 80px; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  grid-gap: 30px;
}

.page_content .product_list .product_data {
  justify-self: center;
}

.page_content .product_list .product_data_content {
  width: 300px;
  margin: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid transparent;
}

.page_content .product_list .product_data_content:hover {
  /* background: #fefefe; */
  /* filter: brightness(90%); */
  border: 2px solid var(--red);
}

.page_content .product_list .product_data_content img {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  margin-right: 0px;
}

.page_content .product_list .product_data_content .text {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.page_content .product_list .product_data_content .text div {
  height: auto;
  margin-bottom: 5px;
}

.page_content .product_list .product_data_content .text div h2 {
  font-size: 14px;
  font-weight: bold;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  max-height: 48px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.page_content .product_list .product_data_content .text h3 {
  font-size: 30px;
  font-weight: bold;
  width: 140px;
}

.page_content .product_list .product_data_content .text p {
  font-size: 12px;
  /* margin-top: 5px; */
  font-weight: bold;
  width: 100px;
}

.page_content .product_list .product_data_content .stars {
  height: 100px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .product_list .product_data_content .stars svg {
  font-size: 18px;
  color: var(--gray);
}

.page_content .product_list .product_data_content .stars .colorful {
  color: gold;
}

h2,
p {
  color: var(--blue);
}

h3 {
  color: var(--red);
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .product {
  position: relative;
  height: 100%;
  width: 100%;
  display: contents;
  /* flex-direction: column; */
}

.page_content .product .product_data {
  /* height: 400px; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.page_content .product .product_data .product_main_data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .product .product_data .product_main_data .product_stars {
  margin-bottom: 10px;
}

.page_content .product .product_data .product_main_data .product_stars svg {
  font-size: 40px;
  color: var(--gray);
}

.page_content
  .product
  .product_data
  .product_main_data
  .product_stars
  .product_stars_colorful {
  color: goldenrod;
}

.page_content .product .product_data .product_main_data .store_link {
  height: 20px;
  margin-top: 5px;
}

.page_content .product .product_data .product_main_data h4 {
  margin-left: 10px;
  color: var(--blue);
  font-size: 18px;
  font-weight: bold;
}

.page_content .product .product_data .product_main_data h3 {
  margin-left: 10px;
  color: var(--red);
  font-size: 48px;
  font-weight: bold;
}

.page_content
  .product
  .product_data
  .product_main_data
  .product_stars
  svg
  + svg {
  margin-left: 15px;
}

.page_content .product .product_data .product_main_data .button1 {
  margin-top: 20px;
  margin-bottom: 5px;
}

.page_content .product .product_data .product_img {
  /* min-width: 400px; */
  /* width: 100%; */
  /* max-width: 800px; */
  margin: 0;
  width: 600px;
  min-height: 378px;
  height: 100%;
  max-height: 400px;
}

.page_content .product .product_data .product_img .react-3d-carousel {
  width: 600px;
}

.page_content
  .product
  .product_data
  .product_img
  .react-3d-carousel
  .slider-single-content {
  box-shadow: 0 0 0 rgb(0 0 0 / 0%);
}

.page_content
  .product
  .product_data
  .product_img
  .react-3d-carousel
  .slider-right {
  left: unset;
  right: -14.25%;
  top: 10.5%;
  width: 14.75%;
  height: 79%;
  margin-left: 0px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.page_content
  .product
  .product_data
  .product_img
  .react-3d-carousel
  .slider-left {
  right: unset;
  left: -14.25%;
  top: 10.5%;
  width: 14.75%;
  height: 79%;
  margin-right: 0px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.page_content
  .product
  .product_data
  .product_img
  .react-3d-carousel
  .slider-right
  div,
.page_content
  .product
  .product_data
  .product_img
  .react-3d-carousel
  .slider-left
  div {
  border: none;
  right: 0%;
  left: 0%;
}

.page_content .product .product_data .product_img .react-3d-carousel img {
  min-width: 200px;
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 12px;
  border: 4px solid var(--white);
}

.page_content .product .product_description {
  width: 100%;
  margin-top: 80px;
  padding: 10px;
}

.page_content .product .product_description h4 {
  color: var(--red);
  font-size: 20px;
  font-weight: bold;
}

.page_content .product .product_description p {
  color: var(--blue);
  font-size: 14px;
  margin: 20px 10px 0 10px;
  text-align: justify;
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .profile_details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0 20px;
}

.page_content .profile_details .profile_data_container {
  width: 40%;
}

.page_content .profile_details .profile_data_container .profile_data {
  position: relative;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 13px 5px;
}

.page_content .profile_details .profile_data_container .profile_data .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page_content .profile_details .profile_data_container .profile_data svg {
  height: 20px;
  width: 20px;
  color: var(--blue);
  margin-right: 5px;
}

.page_content
  .profile_details
  .profile_data_container
  .profile_data
  .data_edit_icon_button {
  position: absolute;
  top: 25px;
  right: 0px;
  font-size: 20px;
  color: var(--blue);
}

.data_edit_icon_button:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.page_content .profile_details .profile_data_container .profile_data h2 {
  font-size: 20px;
  font-weight: bold;
}

.page_content .profile_details .profile_data_container .profile_data p {
  font-size: 18px;
  color: var(--black);
  padding: 5px 0 0 25px;
  width: 95%;
}

.page_content .profile_details .img_container {
  position: relative;
  width: 300px;
  height: 300px;
}

.page_content .profile_details .img_container img {
  width: 300px;
  height: 300px;
  border-radius: 40px;
}

.edit_icon_container {
  position: absolute;
  bottom: 0px;
  right: -10px;
  height: 40px;
  width: 40px;
  background: var(--blue);
  border: 2px solid var(--white);
  color: var(--white);
  font-size: 24px;
  text-align: center;
  padding: 6px 0;
  border-radius: 100px;
}

.edit_icon_container:hover {
  background: var(--red);
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .cart_list {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content .cart_list .order {
  min-width: 640px;
  width: 100%;
  max-width: 1200px;
  border: 2px solid transparent;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.page_content .cart_list .order + .order {
  margin-top: 20px;
  border-top: 1px solid var(--light-gray);
}

.page_content .cart_list .order .order_products {
  width: 70%;
}

.page_content .cart_list .order .order_products p {
  font-size: 14px;
  color: var(--blue);
}

.page_content .cart_list .order .order_products .product {
  margin: 10px 0 30px 10px;
  max-width: 400px;
  width: 50%;
  height: auto;
  min-width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content .cart_list .order .order_products .product .product_img_data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content .cart_list .order .order_products .product .product_img {
  /* height: 40px;
  width: 40px;
  border-radius: 8px; */
  margin-right: 10px;
}

.page_content .cart_list .order .order_products .product .product_img img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
}

.page_content .cart_list .order .order_products .product .product_data {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .cart_list .order .order_products .product .product_data h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--red);
}

.page_content .cart_list .order .order_products .product .product_data h4 {
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .cart_list .order .order_products .product .product_quant {
  display: flex;
  flex-direction: row;
  width: 100px;
}

.page_content .cart_list .order .order_products .product .product_quant svg {
  font-size: 20px;
  color: var(--blue);
}

.page_content .cart_list .order .order_products .product .product_quant button {
  line-height: 10px;
  margin: 0 2px;
}

.page_content
  .cart_list
  .order
  .order_products
  .product
  .product_quant
  button:hover
  svg {
  color: var(--red);
}

.page_content .cart_list .order .order_products .product .product_quant input {
  border: none;
  /* background: var(--light-gray); */
  border: 2px solid var(--blue);
  border-radius: 4px;
  width: 40px;
  height: 30px;
  font-size: 14px;
  color: var(--blue);
  padding: 0 6px;
  text-align: center;
}

.page_content .cart_list .order .order_data {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding-left: 20px;
  border: none;
}

.page_content .cart_list .order .order_products .order_products_store_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page_content
  .cart_list
  .order
  .order_products
  .order_products_store_title
  svg {
  font-size: 16px;
  color: var(--blue);
}

.page_content .cart_list .order .order_products .order_products_store_title h4 {
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .cart_list .order .order_data .order_value {
  position: relative;
  margin-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content .cart_list .order .order_data .order_value .order_value_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content
  .cart_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img {
  /* height: 10px;
  width: 10px; */
}

.page_content
  .cart_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img
  img {
  height: 40px;
  width: 40px;
}

.page_content .cart_list .order .order_data .order_value .order_value_title h4 {
  margin-left: 5px;
  font-size: 24px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .cart_list .order .order_data .order_value .order_total_value {
  margin-top: 5px;
  font-size: 36px;
  font-weight: bold;
  color: var(--red);
}

.page_content .cart_list .order .order_data .order_value .button1 {
  min-width: 200px;
  width: 100%;
  margin-top: 40px;
}

.product {
  margin: 10px 0 30px 10px;
  max-width: 400px;
  width: 50%;
  height: auto;
  min-width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.product .product_img_data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.product .product_img {
  /* height: 40px;
    width: 40px;
    border-radius: 8px; */
  margin-right: 10px;
}

.product .product_img img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
}

.product .product_data {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.product .product_data h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--red);
}

.product .product_data h4 {
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.product .product_quant {
  display: flex;
  flex-direction: row;
  width: 100px;
}

.product .product_quant svg {
  font-size: 20px;
  color: var(--blue);
}

.product .product_quant button {
  line-height: 10px;
  margin: 0 2px;
}

.page_content
  .cart_list
  .order
  .order_products
  .product
  .product_quant
  button:hover
  svg {
  color: var(--red);
}

.product .product_quant input {
  border: none;
  /* background: var(--light-gray); */
  border: 2px solid var(--blue);
  border-radius: 4px;
  width: 40px;
  height: 30px;
  font-size: 14px;
  color: var(--blue);
  padding: 0 6px;
  text-align: center;
}

.page_content .cart_list .order .order_data {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding-left: 20px;
  border: none;
}

.order_products_store_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.order_products_store_title svg {
  font-size: 16px;
  color: var(--blue);
}

.order_products_store_title h4 {
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .cart_list {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content .cart_list .order {
  min-width: 640px;
  width: 100%;
  max-width: 1200px;
  border: 2px solid transparent;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.page_content .cart_list .order + .order {
  margin-top: 20px;
  border-top: 1px solid var(--light-gray);
}

.page_content .cart_list .order .order_addresses {
  width: 70%;
}

.page_content .cart_list .order .order_addresses h3 {
  font-size: 20px;
  margin: 0 0 30px 15px;
}

.page_content .cart_list .order .order_data .order_value {
  position: relative;
  margin-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content .cart_list .order .order_data .order_value .order_value_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content
  .cart_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img {
  /* height: 10px;
  width: 10px; */
}

.page_content
  .cart_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img
  img {
  height: 40px;
  width: 40px;
}

.page_content .cart_list .order .order_data .order_value .order_value_title h4 {
  margin-left: 5px;
  font-size: 24px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .cart_list .order .order_data .order_value .order_total_value {
  margin-top: 5px;
  font-size: 36px;
  font-weight: bold;
  color: var(--red);
}

.page_content .cart_list .order .order_data .order_value .button1 {
  min-width: 200px;
  width: 100%;
  margin-top: 40px;
}

.page_content .cart_list .order .order_addresses .address {
  margin: 10px 0 30px 10px;
  max-width: 600px;
  width: 100%;
  height: auto;
  min-width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.address label {
  font-size: 16px;
  font-weight: bold;
  color: var(--blue);
  cursor: pointer;
}

.address input[type="radio"] {
  display: none;
}

.address input[type="radio"] + label:before {
  border: 1px solid var(--blue);
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 10px 0 5px;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 50%;
}

.address input[type="radio"]:checked + label:before {
  background: var(--red);
  border: 1px solid var(--red);
  color: #fff;
  content: "\2022";
  font-size: 18px;
  line-height: 16px;
  text-align: center;
}

.address input[type="radio"]:checked + label:after {
  font-weight: bold;
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .top_bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid var(--gray);
  padding: 0 0 2px 2px;
}

.page_content .top_bar .top_bar_button {
  font-size: 18px;
  color: var(--blue);
}

.page_content .top_bar .top_bar_button + .top_bar_button {
  margin-left: 40px;
}

.page_content .top_bar .top_bar_button.active {
  font-weight: bold;
  color: var(--red);
}

.page_content .orders_list {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content .orders_list .order {
  min-width: 640px;
  width: 100%;
  max-width: 1200px;
  border-radius: 12px;
  border: 2px solid transparent;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.page_content .orders_list .order + .order {
  margin-top: 20px;
}

.page_content .orders_list .order:hover {
  border: 2px solid var(--red);
}

.page_content .orders_list .order .order_products {
  width: 45%;
}

.page_content .orders_list .order .order_products p {
  font-size: 14px;
  color: var(--blue);
}

.page_content .orders_list .order .order_products .product {
  margin-top: 10px;
  margin-left: 10px;
  max-width: 400px;
  width: 100%;
  height: auto;
  min-width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.page_content .orders_list .order .order_products .product .product_img_data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content .orders_list .order .order_products .product .product_img {
  /* height: 40px;
  width: 40px;
  border-radius: 8px; */
  margin-right: 10px;
}

.page_content .orders_list .order .order_products .product .product_img img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
}

.page_content .orders_list .order .order_products .product .product_data {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .orders_list .order .order_products .product .product_data h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--red);
}

.page_content .orders_list .order .order_products .product .product_data h4 {
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .orders_list .order .order_products .product .product_quant {
  font-size: 16px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .orders_list .order .order_data {
  width: 55%;
  /* height: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-left: 20px;
  border-left: 1px solid var(--gray);
}

.page_content .orders_list .order .order_data .order_data_container {
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content {
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  + .order_data_content {
  margin-top: 20px;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  .order_data_content_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  .order_data_content_title
  svg {
  font-size: 16px;
  color: var(--blue);
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  .order_data_content_title
  h4 {
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  p {
  font-size: 14px;
  color: var(--blue);
  margin-top: 4px;
  margin-left: 10px;
}

.page_content .orders_list .order .order_data .order_value {
  margin-left: 10px;
}

.page_content .orders_list .order .order_data .order_value .order_value_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img {
  /* height: 10px;
  width: 10px; */
}

.page_content
  .orders_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img
  img {
  height: 30px;
  width: 30px;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_value
  .order_value_title
  h4 {
  margin-left: 5px;
  font-size: 18px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .orders_list .order .order_data .order_value .order_total_value {
  margin-top: 5px;
  font-size: 28px;
  font-weight: bold;
  color: var(--red);
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .top_bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid var(--gray);
  padding: 0 0 2px 2px;
}

.page_content .top_bar .top_bar_button {
  font-size: 18px;
  color: var(--blue);
}

.page_content .top_bar .top_bar_button + .top_bar_button {
  margin-left: 40px;
}

.page_content .top_bar .top_bar_button.active {
  font-weight: bold;
  color: var(--red);
}

.page_content .orders_list {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content .orders_list .order {
  min-width: 640px;
  width: 100%;
  max-width: 1200px;
  border-radius: 12px;
  border: 2px solid transparent;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.page_content .orders_list .order + .order {
  margin-top: 20px;
}

.page_content .orders_list .order:hover {
  border: 2px solid var(--red);
}

.page_content .orders_list .order .order_products {
  width: 45%;
}

.page_content .orders_list .order .order_products p {
  font-size: 14px;
  color: var(--blue);
}

.page_content .orders_list .order .order_products .product {
  margin-top: 10px;
  margin-left: 10px;
  max-width: 400px;
  width: 100%;
  height: auto;
  min-width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.page_content .orders_list .order .order_products .product .product_img_data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content .orders_list .order .order_products .product .product_img {
  /* height: 40px;
  width: 40px;
  border-radius: 8px; */
  margin-right: 10px;
}

.page_content .orders_list .order .order_products .product .product_img img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
}

.page_content .orders_list .order .order_products .product .product_data {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .orders_list .order .order_products .product .product_data h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--red);
}

.page_content .orders_list .order .order_products .product .product_data h4 {
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .orders_list .order .order_products .product .product_quant {
  font-size: 16px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .orders_list .order .order_data {
  width: 55%;
  /* height: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-left: 20px;
  border-left: 1px solid var(--gray);
}

.page_content .orders_list .order .order_data .order_data_container {
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content {
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  + .order_data_content {
  margin-top: 20px;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  .order_data_content_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  .order_data_content_title
  svg {
  font-size: 16px;
  color: var(--blue);
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  .order_data_content_title
  h4 {
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  p {
  font-size: 14px;
  color: var(--blue);
  margin-top: 4px;
  margin-left: 10px;
}

.page_content .orders_list .order .order_data .order_value {
  margin-left: 10px;
}

.page_content .orders_list .order .order_data .order_value .order_value_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img {
  /* height: 10px;
  width: 10px; */
}

.page_content
  .orders_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img
  img {
  height: 30px;
  width: 30px;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_value
  .order_value_title
  h4 {
  margin-left: 5px;
  font-size: 18px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .orders_list .order .order_data .order_value .order_total_value {
  margin-top: 5px;
  font-size: 28px;
  font-weight: bold;
  color: var(--red);
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content {
  /* width: 100%; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content .order {
  width: 100%;
  max-width: 1200px;
  min-width: 700px;
}

.page_content .order .order_data {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.page_content .order .order_data .order_data_list {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, minmax(300px, auto));
}

.page_content .order .order_data .order_data_list .order_data_item {
}

.page_content
  .order
  .order_data
  .order_data_list
  .order_data_item
  .order_data_item_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page_content
  .order
  .order_data
  .order_data_list
  .order_data_item
  .order_data_item_title
  svg {
  font-size: 22px;
  color: var(--blue);
  margin-right: 10px;
}

.page_content
  .order
  .order_data
  .order_data_list
  .order_data_item
  .order_data_item_title
  h4 {
  font-size: 20px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .order .order_data .order_data_list .order_data_item p {
  font-size: 16px;
  font-weight: normal;
  color: var(--blue);
  margin-top: 10px;
}

.page_content .order .order_data .order_value {
}

.page_content .order .order_data .order_value .order_value_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.page_content .order .order_data .order_value .order_value_title img {
  height: 60px;
  width: 60px;
}

.page_content .order .order_data .order_value .order_value_title h3 {
  font-size: 24px;
  font-weight: bold;
  color: var(--blue);
  margin-left: 10px;
}

.page_content .order .order_data .order_value .order_total_value {
  /* width: 100%; */
}

.page_content .order .order_data .order_value .order_total_value h1 {
  font-size: 28px;
  font-weight: bold;
  color: var(--red);
}

.page_content .order .order_data_2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
}

.page_content .order .order_data_2 .order_products {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.page_content .order .order_data_2 .order_products .order_products_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page_content .order .order_data_2 .order_products .order_products_title svg {
  font-size: 22px;
  color: var(--blue);
  margin-right: 10px;
}

.page_content .order .order_data_2 .order_products .order_products_title h4 {
  font-size: 20px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .order .order_data_2 .order_products .product {
  margin-top: 10px;
  margin-left: 10px;
  max-width: 400px;
  width: 100%;
  min-width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content .order .order_data_2 .order_products .product .product_img_data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content .order .order_data_2 .order_products .product .product_img {
  /* height: 40px;
  width: 40px;
  border-radius: 8px; */
  margin-right: 10px;
}

.page_content .order .order_data_2 .order_products .product .product_img img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
}

.page_content .order .order_data_2 .order_products .product .product_data {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .order .order_data_2 .order_products .product .product_data h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--red);
}

.page_content .order .order_data_2 .order_products .product .product_data h4 {
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .order .order_data_2 .order_products .product .product_quant {
  font-size: 16px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .order .order_data_2 .order_status {
}

.page_content .order .order_data_2 .order_status .order_status_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page_content .order .order_data_2 .order_status .order_status_title svg {
  font-size: 22px;
  color: var(--blue);
  margin-right: 10px;
}

.page_content .order .order_data_2 .order_status .order_status_title h4 {
  font-size: 20px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .order .order_data_2 .order_status .order_status_status {
  font-size: 16px;
  font-weight: normal;
  color: var(--blue);
  margin-top: 10px;
}

.content {
  position: relative;
  min-width: 760px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--register-page-background);
}

.logo_container {
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: 40px;
}

.logo_container img {
  min-width: 200px;
  width: 70%;
  max-width: 300px;
  height: auto;
}

.logo_container img + img {
  margin-top: 0px;
}

.form_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  min-height: 600px;
  width: 100%;
  min-width: 600px;
  max-width: 1200px;
  background: var(--register-page-form-background);
  padding: 20px 6% 40px;
  /* border: 2px solid var(--register-page-form-border-color); */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  border-radius: 12px;
}

.form_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form_content h1 {
  margin: 10px 0 20px;
  color: var(--register-page-form-title-color);
}

.text_input_line {
  display: flex;
  justify-content: space-between;
  min-width: 300px;
  width: 100%;
  max-width: 600px;
}

.text_input_container {
  position: relative;
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  padding: 12px 0 24px;
  margin: 0;
}

.text_input_container .text_input_title {
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 10px;
  padding: 2px 4px;
  background: var(--register-page-text-input-background);
  color: var(--register-page-text-input-label-color);
}

.text_input_container .text_input_content {
  height: 40px;
  width: 100%;
  border: 2px solid var(--register-page-text-input-border-color);
  padding: 0 35px 0 10px;
  border-radius: 8px;
}

.button1,
.button2 {
  min-width: 300px;
  width: 100%;
  max-width: 600px;
}

.forget_password {
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: flex-start;
  padding-left: 10px;
}

.forget_password span {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: var(--red);
}

.checkbox_container {
  height: 40px;
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  padding: 12px 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.checkbox_container input[type="checkbox"] {
  display: none;
}
.checkbox_container label {
  cursor: pointer;
}

.checkbox_container input[type="checkbox"] + label:before {
  border: 1px solid var(--blue);
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 10px 0 5px;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 4px;
}

.checkbox_container input[type="checkbox"]:checked + label:before {
  background: var(--red);
  color: #fff;
  content: "\2713";
  text-align: center;
}

.checkbox_container input[type="checkbox"]:checked + label:after {
  font-weight: bold;
}

.checkbox_container a {
  font-weight: bold;
  color: var(--red);
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .profile_details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0 20px;
}

.page_content .profile_details .profile_data_container {
  width: 40%;
}

.page_content .profile_details .profile_data_container .profile_data {
  position: relative;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 13px 5px;
}

.page_content .profile_details .profile_data_container .profile_data .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page_content .profile_details .profile_data_container .profile_data svg {
  height: 20px;
  width: 20px;
  color: var(--blue);
  margin-right: 5px;
}

.page_content
  .profile_details
  .profile_data_container
  .profile_data
  .data_edit_icon_button {
  position: absolute;
  top: 25px;
  right: 0px;
  font-size: 20px;
  color: var(--blue);
}

.data_edit_icon_button:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.page_content .profile_details .profile_data_container .profile_data h2 {
  font-size: 20px;
  font-weight: bold;
}

.page_content .profile_details .profile_data_container .profile_data p {
  font-size: 18px;
  color: var(--black);
  padding: 5px 0 0 25px;
  width: 95%;
}

.page_content .profile_details .img_container {
  position: relative;
  width: 300px;
  height: 300px;
}

.page_content .profile_details .img_container img {
  width: 300px;
  height: 300px;
  border-radius: 40px;
}

.page_content .profile_details .img_container input {
  display: none;
}

.edit_icon_container {
  position: absolute;
  bottom: 0px;
  right: -10px;
  height: 40px;
  width: 40px;
  background: var(--blue);
  border: 2px solid var(--white);
  color: var(--white);
  font-size: 24px;
  text-align: center;
  padding: 6px 0;
  border-radius: 100px;
  cursor: pointer;
}

.edit_icon_container:hover {
  background: var(--red);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

.admin_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 80px;
  background: var(--sidebar-background);
  padding: 5px 15px;
  transition: all 0.5s ease;
}
.admin_sidebar.active {
  width: 300px;
}

.admin_sidebar .head {
  height: 130px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.admin_sidebar.active .head {
  display: flex;
  flex-direction: row;
}

.admin_sidebar .head .logo {
  margin-top: 10px;
  display: flex;
  height: 50%;
  width: auto;
  pointer-events: none;
  transition: all 0.5s ease;
}
.admin_sidebar.active .head .logo {
  margin-top: 0;
  height: 100%;
  pointer-events: none;
}

.admin_sidebar .head .logo img {
  height: auto;
  width: 100%;
}
.admin_sidebar.active .head .logo img {
  height: 100%;
  width: auto;
}

.admin_sidebar .head .menu {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.admin_sidebar.active .head .menu {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.admin_sidebar #menu_icon {
  color: var(--sidebar-color);
  font-size: 24px;
  height: 50px;
  width: 50px;
  text-align: center;
  padding: 13px 0;
  border-radius: 12px;
}
.admin_sidebar #menu_icon:hover {
  color: var(--sidebar-hover-color);
  background: var(--sidebar-hover-background);
}

.admin_sidebar ul {
  margin-top: 40px;
}

.admin_sidebar ul li {
  position: relative;
  height: 50px;
  width: 100%;
  margin: 10px 0px;
  list-style: none;
}

.caption {
  position: absolute;
  left: calc(75px + 140px / 2);
  top: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 6px;
  height: 30px;
  line-height: 30px;
  width: 140px;
  background: var(--sidebar-caption-background);
  color: var(--sidebar-caption-color);
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0s;
  opacity: 0;
  pointer-events: none;
  display: block;
}
.admin_sidebar.active .caption {
  display: none;
}
.admin_sidebar ul li:hover .caption {
  transition: all 0.5s ease;
  opacity: 1;
  top: 50%;
}

.admin_sidebar #menu_link {
  margin-bottom: 50px;
}

.admin_sidebar .nav_link {
  color: var(--sidebar-color);
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
  font-size: 16px;
}
.admin_sidebar.active .nav_link {
  width: 100%;
}
.admin_sidebar .nav_link:hover {
  background: var(--sidebar-hover-background);
  color: var(--sidebar-hover-color);
}

.admin_sidebar .sidebar_icon {
  height: 50px;
  min-width: 50px;
  border-radius: 12px;
  font-size: 24px;
  padding: 13px 0;
  text-align: center;
}

.admin_sidebar .link_title {
  opacity: 0;
  pointer-events: none;
  transition: all 0.1s ease;
}
.admin_sidebar.active .link_title {
  opacity: 1;
  pointer-events: auto;
}

.admin_sidebar .logout_button_container {
  position: absolute;
  bottom: 5px;
  height: 50px;
  width: calc(100% - 30px);
  margin: 10px 0px;
}

.admin_sidebar .logout_button {
  color: var(--sidebar-color);
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
}
.admin_sidebar.active .logout_button {
  width: 100%;
}
.admin_sidebar .logout_button:hover {
  background: var(--sidebar-hover-background);
  color: var(--sidebar-hover-color);
}

.admin_sidebar .logout_button_container:hover .caption {
  transition: all 0.5s ease;
  opacity: 1;
  top: 50%;
}

.admin_sidebar ul li .current_page {
  color: var(--sidebar-current-page-color);
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
  /* pointer-events: none; */
}
.admin_sidebar.active ul li .current_page {
  width: 100%;
  display: flex;
  align-items: center;
}
.admin_sidebar.active ul li .current_page i,
.admin_sidebar ul li .current_page i {
  font-size: 24px;
}
.admin_sidebar ul li .current_page .link_title,
.admin_sidebar.active ul li .current_page .link_title {
  pointer-events: none;
  font-size: 16px;
  font-weight: bold;
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_content .head h1 {
  margin: 0;
  margin-right: 5px;
}

.page_content .profile_details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0 20px;
}

/* .page_content .profile_details .profile_data_container .profile_data {
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 13px 5px;
}

.page_content .profile_details .profile_data_container .profile_data h2 {
  font-size: 14px;
}

.page_content .profile_details .profile_data_container .profile_data p {
  font-size: 18px;
  padding: 5px;
} */

.page_content .profile_details .profile_data_container {
  position: relative;
  /* width: 600px; */
  padding: 0 20px 0 0;
}

.page_content .profile_details .profile_data_container .text_input_container {
  position: relative;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 12px 0 24px;
  margin: 0;
}

.page_content
  .profile_details
  .profile_data_container
  .text_input_container
  svg {
  position: absolute;
  top: 22px;
  right: 10px;
  color: var(--red);
  height: 20px;
  width: 20px;
}

.page_content
  .profile_details
  .profile_data_container
  .text_input_container
  .text_input_title {
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 10px;
  padding: 2px 4px;
  background: var(--register-page-text-input-background);
  color: var(--register-page-text-input-label-color);
}

.page_content
  .profile_details
  .profile_data_container
  .text_input_container
  .text_input_content {
  height: 40px;
  width: 100%;
  border: 2px solid var(--register-page-text-input-border-color);
  padding: 0 35px 0 10px;
  border-radius: 8px;
}

.page_content .text_input_line {
  display: flex;
  justify-content: space-between;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
}

.page_content .profile_details .img_container {
  position: relative;
  width: 300px;
  height: 300px;
}

.page_content .profile_details .img_container img {
  width: 300px;
  height: 300px;
  border-radius: 40px;
}

.page_content .profile_details .img_container input {
  display: none;
}

.edit_icon_container {
  position: absolute;
  bottom: 0px;
  right: -10px;
  height: 40px;
  width: 40px;
  background: var(--blue);
  border: 2px solid var(--white);
  color: var(--white);
  font-size: 24px;
  text-align: center;
  padding: 6px 0;
  border-radius: 100px;
  cursor: pointer;
}

.edit_icon_container:hover {
  background: var(--red);
}

.page_content .profile_details .profile_data_container .button1 {
  width: 100%;
  min-width: 400px;
  margin-top: 80px;
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 0 80px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

/* .page_content .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.page_content .head h1 {
  margin: 0;
  margin-right: 5px;
}

.page_content .head img {
  height: 150px;
  width: auto;
} */

.page_content .store_list {
  width: 100%;
  /* margin-top: 80px; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  grid-gap: 30px;
}

.page_content .store_list .store_data {
  justify-self: center;
}

.page_content .store_list .store_data_content {
  width: 300px;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid transparent;
}

.page_content .store_list .store_data_content:hover {
  border: 2px solid var(--red);
}

.page_content .store_list .store_data_content img {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  margin-right: 10px;
}

.page_content .store_list .store_data_content div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .store_list .store_data_content div h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.page_content .store_list .store_data_content div div {
  height: 40px;
}

.page_content .store_list .store_data_content div div p {
  font-size: 14px;
  margin-left: 5px;
}

h2,
p {
  color: var(--blue);
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_content .head h1 {
  margin: 0;
  margin-right: 5px;
}

.page_content .store_details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0;
}

.page_content .store_details .store_data_container .store_data {
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 12px 5px;
}

.page_content .store_details .store_data_container .store_data .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page_content .store_details .store_data_container .store_data svg {
  height: 20px;
  width: 20px;
  color: var(--blue);
  margin-right: 5px;
}

.page_content .store_details .store_data_container .store_data h2 {
  font-size: 20px;
  font-weight: bold;
}

.page_content .store_details .store_data_container .store_data p {
  font-size: 18px;
  padding: 5px 0 0 25px;
  color: var(--black);
}

.page_content .store_details .store_data_container .button1 {
  width: 100%;
  margin-top: 80px;
}

.page_content .store_details .img_container img {
  width: 340px;
  height: 340px;
  border-radius: 12px;
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_content .head h1 {
  margin-right: 5px;
}

.page_content .register_form {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

.page_content .register_form .register_fields_container {
  width: 40%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.page_content .register_form .register_fields {
  width: 100%;
  max-width: 1000px;
  min-width: 400px;
}

.page_content .register_form .register_fields + .register_fields {
  margin-top: 40px;
}

.page_content .register_form .register_fields h2 {
  font-size: 18px;
  color: var(--red);
  margin-bottom: 20px;
}

.page_content .text_input_container {
  position: relative;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 12px 0 24px;
  margin: 0;
}

.page_content .text_input_container .text_input_title {
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 10px;
  padding: 2px 4px;
  background: var(--register-page-text-input-background);
  color: var(--register-page-text-input-label-color);
}

.page_content .text_input_container .text_input_content {
  height: 40px;
  width: 100%;
  border: 2px solid var(--register-page-text-input-border-color);
  padding: 0 10px;
  border-radius: 8px;
}

.page_content .text_input_line {
  display: flex;
  justify-content: space-between;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
}

.button1 {
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  margin-top: 80px;
}

.register_form img {
  height: auto;
  width: 40%;
  max-width: 300px;
  min-width: 200px;
}

.content {
  position: relative;
  min-width: 760px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--register-page-background);
}

.logo_container {
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: 40px;
}

.logo_container img {
  min-width: 200px;
  width: 70%;
  max-width: 300px;
  height: auto;
}

.logo_container img + img {
  margin-top: 0px;
}

.form_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  min-height: 600px;
  width: 100%;
  min-width: 600px;
  max-width: 1200px;
  background: var(--register-page-form-background);
  padding: 20px 6% 40px;
  /* border: 2px solid var(--register-page-form-border-color); */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  border-radius: 12px;
}

.form_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form_content h1 {
  margin: 10px 0 20px;
  color: var(--register-page-form-title-color);
}

.text_input_line {
  display: flex;
  justify-content: space-between;
  min-width: 300px;
  width: 100%;
  max-width: 600px;
}

.text_input_container {
  position: relative;
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  padding: 12px 0 24px;
  margin: 0;
}

.text_input_container .text_input_title {
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 10px;
  padding: 2px 4px;
  background: var(--register-page-text-input-background);
  color: var(--register-page-text-input-label-color);
}

.text_input_container .text_input_content {
  height: 40px;
  width: 100%;
  border: 2px solid var(--register-page-text-input-border-color);
  padding: 0 35px 0 10px;
  border-radius: 8px;
}

.button1,
.button2 {
  min-width: 300px;
  width: 100%;
  max-width: 600px;
}

.forget_password {
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: flex-start;
  padding-left: 10px;
}

.forget_password span {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: var(--red);
}

.user_type {
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

.user_type label {
  font-size: 16px;
  font-weight: bold;
  color: var(--blue);
  cursor: pointer;
}

.user_type .collaborator {
  margin-left: 20px;
}

.user_type {
  height: 40px;
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  padding: 12px 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.user_type input[type="radio"] {
  display: none;
}

.user_type input[type="radio"] + label:before {
  border: 1px solid var(--blue);
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 10px 0 5px;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 50%;
}

.user_type input[type="radio"]:checked + label:before {
  background: var(--red);
  border: 1px solid var(--red);
  color: #fff;
  content: "\2022";
  font-size: 18px;
  line-height: 16px;
  text-align: center;
}

.user_type input[type="radio"]:checked + label:after {
  font-weight: bold;
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .profile_details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0 20px;
}

.page_content .profile_details .profile_data_container {
  width: 40%;
}

.page_content .profile_details .profile_data_container .profile_data {
  position: relative;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 13px 5px;
}

.page_content .profile_details .profile_data_container .profile_data .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page_content .profile_details .profile_data_container .profile_data svg {
  height: 20px;
  width: 20px;
  color: var(--blue);
  margin-right: 5px;
}

.page_content
  .profile_details
  .profile_data_container
  .profile_data
  .data_edit_icon_button {
  position: absolute;
  top: 25px;
  right: 0px;
  font-size: 20px;
  color: var(--blue);
}

.data_edit_icon_button:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.page_content .profile_details .profile_data_container .profile_data h2 {
  font-size: 20px;
  font-weight: bold;
}

.page_content .profile_details .profile_data_container .profile_data p {
  font-size: 18px;
  color: var(--black);
  padding: 5px 0 0 25px;
  width: 95%;
}

.page_content .profile_details .img_container {
  position: relative;
  width: 300px;
  height: 300px;
}

.page_content .profile_details .img_container img {
  width: 300px;
  height: 300px;
  border-radius: 40px;
}

.page_content .profile_details .img_container input {
  display: none;
}

.edit_icon_container {
  position: absolute;
  bottom: 0px;
  right: -10px;
  height: 40px;
  width: 40px;
  background: var(--blue);
  border: 2px solid var(--white);
  color: var(--white);
  font-size: 24px;
  text-align: center;
  padding: 6px 0;
  border-radius: 100px;
  cursor: pointer;
}

.edit_icon_container:hover {
  background: var(--red);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
}

.collab_sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 80px;
  background: var(--sidebar-background);
  padding: 5px 15px;
  transition: all 0.5s ease;
}
.collab_sidebar.active {
  width: 300px;
}

.collab_sidebar .head {
  height: 130px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.collab_sidebar.active .head {
  display: flex;
  flex-direction: row;
}

.collab_sidebar .head .logo {
  margin-top: 10px;
  display: flex;
  height: 50%;
  width: auto;
  pointer-events: none;
  transition: all 0.5s ease;
}
.collab_sidebar.active .head .logo {
  margin-top: 0;
  height: 100%;
  pointer-events: none;
}

.collab_sidebar .head .logo img {
  height: auto;
  width: 100%;
}
.collab_sidebar.active .head .logo img {
  height: 100%;
  width: auto;
}

.collab_sidebar .head .menu {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.collab_sidebar.active .head .menu {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.collab_sidebar #menu_icon {
  color: var(--sidebar-color);
  font-size: 24px;
  height: 50px;
  width: 50px;
  text-align: center;
  padding: 13px 0;
  border-radius: 12px;
}
.collab_sidebar #menu_icon:hover {
  color: var(--sidebar-hover-color);
  background: var(--sidebar-hover-background);
}

.collab_sidebar ul {
  margin-top: 40px;
}

.collab_sidebar ul li {
  position: relative;
  height: 50px;
  width: 100%;
  margin: 10px 0px;
  list-style: none;
}

.caption {
  position: absolute;
  left: calc(75px + 140px / 2);
  top: 0;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 6px;
  height: 30px;
  line-height: 30px;
  width: 140px;
  background: var(--sidebar-caption-background);
  color: var(--sidebar-caption-color);
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0s;
  opacity: 0;
  pointer-events: none;
  display: block;
}
.collab_sidebar.active .caption {
  display: none;
}
.collab_sidebar ul li:hover .caption {
  transition: all 0.5s ease;
  opacity: 1;
  top: 50%;
}

.collab_sidebar #menu_link {
  margin-bottom: 50px;
}

.collab_sidebar .nav_link {
  color: var(--sidebar-color);
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
  font-size: 16px;
}
.collab_sidebar.active .nav_link {
  width: 100%;
}
.collab_sidebar .nav_link:hover {
  background: var(--sidebar-hover-background);
  color: var(--sidebar-hover-color);
}

.collab_sidebar .sidebar_icon {
  height: 50px;
  min-width: 50px;
  border-radius: 12px;
  font-size: 24px;
  padding: 13px 0;
  text-align: center;
}

.collab_sidebar .link_title {
  opacity: 0;
  pointer-events: none;
  transition: all 0.1s ease;
}
.collab_sidebar.active .link_title {
  opacity: 1;
  pointer-events: auto;
}

.collab_sidebar .logout_button_container {
  position: absolute;
  bottom: 5px;
  height: 50px;
  width: calc(100% - 30px);
  margin: 10px 0px;
}

.collab_sidebar .logout_button {
  color: var(--sidebar-color);
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
}
.collab_sidebar.active .logout_button {
  width: 100%;
}
.collab_sidebar .logout_button:hover {
  background: var(--sidebar-hover-background);
  color: var(--sidebar-hover-color);
}

.collab_sidebar .logout_button_container:hover .caption {
  transition: all 0.5s ease;
  opacity: 1;
  top: 50%;
}

.collab_sidebar ul li .current_page {
  color: var(--sidebar-current-page-color);
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  border-radius: 12px;
  white-space: nowrap;
  /* pointer-events: none; */
}
.collab_sidebar.active ul li .current_page {
  width: 100%;
  display: flex;
  align-items: center;
}
.collab_sidebar.active ul li .current_page i,
.collab_sidebar ul li .current_page i {
  font-size: 24px;
}
.collab_sidebar ul li .current_page .link_title,
.collab_sidebar.active ul li .current_page .link_title {
  pointer-events: none;
  font-size: 16px;
  font-weight: bold;
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_content .head h1 {
  margin: 0;
  margin-right: 5px;
}

.page_content .profile_details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0 20px;
}

/* .page_content .profile_details .profile_data_container .profile_data {
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 13px 5px;
}

.page_content .profile_details .profile_data_container .profile_data h2 {
  font-size: 14px;
}

.page_content .profile_details .profile_data_container .profile_data p {
  font-size: 18px;
  padding: 5px;
} */

.page_content .profile_details .profile_data_container {
  position: relative;
  /* width: 600px; */
  padding: 0 20px 0 0;
}

.page_content .profile_details .profile_data_container .text_input_container {
  position: relative;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 12px 0 24px;
  margin: 0;
}

.page_content
  .profile_details
  .profile_data_container
  .text_input_container
  svg {
  position: absolute;
  top: 22px;
  right: 10px;
  color: var(--red);
  height: 20px;
  width: 20px;
}

.page_content
  .profile_details
  .profile_data_container
  .text_input_container
  .text_input_title {
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 10px;
  padding: 2px 4px;
  background: var(--register-page-text-input-background);
  color: var(--register-page-text-input-label-color);
}

.page_content
  .profile_details
  .profile_data_container
  .text_input_container
  .text_input_content {
  height: 40px;
  width: 100%;
  border: 2px solid var(--register-page-text-input-border-color);
  padding: 0 35px 0 10px;
  border-radius: 8px;
}

.page_content .text_input_line {
  display: flex;
  justify-content: space-between;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
}

.page_content .profile_details .img_container {
  position: relative;
  width: 300px;
  height: 300px;
}

.page_content .profile_details .img_container img {
  width: 300px;
  height: 300px;
  border-radius: 40px;
}

.page_content .profile_details .img_container input {
  display: none;
}

.edit_icon_container {
  position: absolute;
  bottom: 0px;
  right: -10px;
  height: 40px;
  width: 40px;
  background: var(--blue);
  border: 2px solid var(--white);
  color: var(--white);
  font-size: 24px;
  text-align: center;
  padding: 6px 0;
  border-radius: 100px;
  cursor: pointer;
}

.edit_icon_container:hover {
  background: var(--red);
}

.page_content .profile_details .profile_data_container .button1 {
  width: 100%;
  min-width: 400px;
  margin-top: 80px;
}

.page_content .collabs_list {
  width: 100%;
  display: grid;
  grid-gap: 25px;
}

.page_content .collabs_list .collab_data_container {
  justify-self: center;
}

.page_content .collabs_list .collab_data_container .collab_data {
  position: relative;
  width: 800px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid transparent;
}

.page_content .collabs_list .collab_data_container .collab_data:hover {
  border: 2px solid var(--red);
}

.page_content .collabs_list .collab_data_container .collab_data .collab_main {
  width: 100px;
  height: 100px;
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_main
  img {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  border-radius: 10px;
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_details {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 10px;
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_details
  h2 {
  font-size: 18px;
  font-weight: bold;
  color: var(--red);
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_details
  h3 {
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
  margin-top: 5px;
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_details
  .data_group {
  margin-top: 25px;
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_details
  .data_group
  p {
  display: inline-block;
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_details
  .data_group
  p
  + p {
  margin-left: 5px;
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_delete_icon_button {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 30px;
  color: var(--blue);
  font-size: 24px;
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_delete_icon_button:hover {
  opacity: 0.8;
}

p {
  color: var(--blue);
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_content .head h1 {
  margin-right: 5px;
}

.page_content .register_form {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

.page_content .register_form .register_fields_container {
  width: 40%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.page_content .register_form .register_fields {
  width: 100%;
  max-width: 1000px;
  min-width: 400px;
}

.page_content .text_input_container {
  position: relative;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 12px 0 24px;
  margin: 0;
}

.page_content .text_input_container .text_input_title {
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 10px;
  padding: 2px 4px;
  background: var(--register-page-text-input-background);
  color: var(--register-page-text-input-label-color);
}

.page_content .text_input_container .text_input_content {
  height: 40px;
  width: 100%;
  border: 2px solid var(--register-page-text-input-border-color);
  padding: 0 10px;
  border-radius: 8px;
}

.page_content .text_input_line {
  display: flex;
  justify-content: space-between;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
}

.button1 {
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  margin-top: 80px;
}
/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .profile_details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0 20px;
}

.page_content .profile_details .profile_data_container {
  width: 40%;
}

.page_content .profile_details .profile_data_container .profile_data {
  position: relative;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 13px 5px;
}

.page_content .profile_details .profile_data_container .profile_data .title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page_content .profile_details .profile_data_container .profile_data svg {
  height: 20px;
  width: 20px;
  color: var(--blue);
  margin-right: 5px;
}

.page_content
  .profile_details
  .profile_data_container
  .profile_data
  .data_edit_icon_button {
  position: absolute;
  top: 25px;
  right: 0px;
  font-size: 20px;
  color: var(--blue);
}

.data_edit_icon_button:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.page_content .profile_details .profile_data_container .profile_data h2 {
  font-size: 20px;
  font-weight: bold;
}

.page_content .profile_details .profile_data_container .profile_data p {
  font-size: 18px;
  color: var(--black);
  padding: 5px 0 0 25px;
  width: 95%;
}

.page_content .profile_details .img_container {
  position: relative;
  width: 300px;
  height: 300px;
}

.page_content .profile_details .img_container img {
  width: 300px;
  height: 300px;
  border-radius: 40px;
}

.page_content .profile_details .img_container input {
  display: none;
}

.edit_icon_container {
  position: absolute;
  bottom: 0px;
  right: -10px;
  height: 40px;
  width: 40px;
  background: var(--blue);
  border: 2px solid var(--white);
  color: var(--white);
  font-size: 24px;
  text-align: center;
  padding: 6px 0;
  border-radius: 100px;
  cursor: pointer;
}

.edit_icon_container:hover {
  background: var(--red);
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_content .head h1 {
  margin: 0;
  margin-right: 5px;
}

.page_content .profile_details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0 20px;
}

/* .page_content .profile_details .profile_data_container .profile_data {
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 13px 5px;
}

.page_content .profile_details .profile_data_container .profile_data h2 {
  font-size: 14px;
}

.page_content .profile_details .profile_data_container .profile_data p {
  font-size: 18px;
  padding: 5px;
} */

.page_content .profile_details .profile_data_container {
  position: relative;
  /* width: 600px; */
  padding: 0 20px 0 0;
}

.page_content .profile_details .profile_data_container .text_input_container {
  position: relative;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 12px 0 24px;
  margin: 0;
}

.page_content
  .profile_details
  .profile_data_container
  .text_input_container
  svg {
  position: absolute;
  top: 22px;
  right: 10px;
  color: var(--red);
  height: 20px;
  width: 20px;
}

.page_content
  .profile_details
  .profile_data_container
  .text_input_container
  .text_input_title {
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 10px;
  padding: 2px 4px;
  background: var(--register-page-text-input-background);
  color: var(--register-page-text-input-label-color);
}

.page_content
  .profile_details
  .profile_data_container
  .text_input_container
  .text_input_content {
  height: 40px;
  width: 100%;
  border: 2px solid var(--register-page-text-input-border-color);
  padding: 0 35px 0 10px;
  border-radius: 8px;
}

.page_content .text_input_line {
  display: flex;
  justify-content: space-between;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
}

.page_content .profile_details .img_container {
  position: relative;
  width: 300px;
  height: 300px;
}

.page_content .profile_details .img_container img {
  width: 300px;
  height: 300px;
  border-radius: 40px;
}

.page_content .profile_details .img_container input {
  display: none;
}

.edit_icon_container {
  position: absolute;
  bottom: 0px;
  right: -10px;
  height: 40px;
  width: 40px;
  background: var(--blue);
  border: 2px solid var(--white);
  color: var(--white);
  font-size: 24px;
  text-align: center;
  padding: 6px 0;
  border-radius: 100px;
  cursor: pointer;
}

.edit_icon_container:hover {
  background: var(--red);
}

.page_content .profile_details .profile_data_container .button1 {
  width: 100%;
  min-width: 400px;
  margin-top: 80px;
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .top_bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid var(--gray);
  padding: 0 0 2px 2px;
}

.page_content .top_bar .top_bar_button {
  font-size: 18px;
  color: var(--blue);
}

.page_content .top_bar .top_bar_button + .top_bar_button {
  margin-left: 40px;
}

.page_content .top_bar .top_bar_button.active {
  font-weight: bold;
  color: var(--red);
}

.page_content .orders_list {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content .orders_list .order {
  min-width: 640px;
  width: 100%;
  max-width: 1200px;
  border-radius: 12px;
  border: 2px solid transparent;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.page_content .orders_list .order + .order {
  margin-top: 20px;
}

.page_content .orders_list .order:hover {
  border: 2px solid var(--red);
}

.page_content .orders_list .order .order_products {
  width: 45%;
}

.page_content .orders_list .order .order_products p {
  font-size: 14px;
  color: var(--blue);
}

.page_content .orders_list .order .order_products .product {
  margin-top: 10px;
  margin-left: 10px;
  max-width: 400px;
  width: 100%;
  height: auto;
  min-width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.page_content .orders_list .order .order_products .product .product_img_data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content .orders_list .order .order_products .product .product_img {
  /* height: 40px;
  width: 40px;
  border-radius: 8px; */
  margin-right: 10px;
}

.page_content .orders_list .order .order_products .product .product_img img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
}

.page_content .orders_list .order .order_products .product .product_data {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .orders_list .order .order_products .product .product_data h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--red);
}

.page_content .orders_list .order .order_products .product .product_data h4 {
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .orders_list .order .order_products .product .product_quant {
  font-size: 16px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .orders_list .order .order_data {
  width: 55%;
  /* height: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-left: 20px;
  border-left: 1px solid var(--gray);
}

.page_content .orders_list .order .order_data .order_data_container {
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content {
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  + .order_data_content {
  margin-top: 20px;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  .order_data_content_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  .order_data_content_title
  svg {
  font-size: 16px;
  color: var(--blue);
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  .order_data_content_title
  h4 {
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  p {
  font-size: 14px;
  color: var(--blue);
  margin-top: 4px;
  margin-left: 10px;
}

.page_content .orders_list .order .order_data .order_value {
  margin-left: 10px;
}

.page_content .orders_list .order .order_data .order_value .order_value_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img {
  /* height: 10px;
  width: 10px; */
}

.page_content
  .orders_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img
  img {
  height: 30px;
  width: 30px;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_value
  .order_value_title
  h4 {
  margin-left: 5px;
  font-size: 18px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .orders_list .order .order_data .order_value .order_total_value {
  margin-top: 5px;
  font-size: 28px;
  font-weight: bold;
  color: var(--red);
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .top_bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid var(--gray);
  padding: 0 0 2px 2px;
}

.page_content .top_bar .top_bar_button {
  font-size: 18px;
  color: var(--blue);
}

.page_content .top_bar .top_bar_button + .top_bar_button {
  margin-left: 40px;
}

.page_content .top_bar .top_bar_button.active {
  font-weight: bold;
  color: var(--red);
}

.page_content .orders_list {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content .orders_list .order {
  min-width: 640px;
  width: 100%;
  max-width: 1200px;
  border-radius: 12px;
  border: 2px solid transparent;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.page_content .orders_list .order + .order {
  margin-top: 20px;
}

.page_content .orders_list .order:hover {
  border: 2px solid var(--red);
}

.page_content .orders_list .order .order_products {
  width: 45%;
}

.page_content .orders_list .order .order_products p {
  font-size: 14px;
  color: var(--blue);
}

.page_content .orders_list .order .order_products .product {
  margin-top: 10px;
  margin-left: 10px;
  max-width: 400px;
  width: 100%;
  height: auto;
  min-width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.page_content .orders_list .order .order_products .product .product_img_data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content .orders_list .order .order_products .product .product_img {
  /* height: 40px;
  width: 40px;
  border-radius: 8px; */
  margin-right: 10px;
}

.page_content .orders_list .order .order_products .product .product_img img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
}

.page_content .orders_list .order .order_products .product .product_data {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .orders_list .order .order_products .product .product_data h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--red);
}

.page_content .orders_list .order .order_products .product .product_data h4 {
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .orders_list .order .order_products .product .product_quant {
  font-size: 16px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .orders_list .order .order_data {
  width: 55%;
  /* height: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-left: 20px;
  border-left: 1px solid var(--gray);
}

.page_content .orders_list .order .order_data .order_data_container {
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content {
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  + .order_data_content {
  margin-top: 20px;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  .order_data_content_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  .order_data_content_title
  svg {
  font-size: 16px;
  color: var(--blue);
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  .order_data_content_title
  h4 {
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  p {
  font-size: 14px;
  color: var(--blue);
  margin-top: 4px;
  margin-left: 10px;
}

.page_content .orders_list .order .order_data .order_value {
  margin-left: 10px;
}

.page_content .orders_list .order .order_data .order_value .order_value_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img {
  /* height: 10px;
  width: 10px; */
}

.page_content
  .orders_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img
  img {
  height: 30px;
  width: 30px;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_value
  .order_value_title
  h4 {
  margin-left: 5px;
  font-size: 18px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .orders_list .order .order_data .order_value .order_total_value {
  margin-top: 5px;
  font-size: 28px;
  font-weight: bold;
  color: var(--red);
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content {
  /* width: 100%; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content .order {
  width: 100%;
  max-width: 1200px;
  min-width: 700px;
}

.page_content .order .order_data {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.page_content .order .order_data .order_data_list {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, minmax(300px, auto));
}

.page_content .order .order_data .order_data_list .order_data_item {
}

.page_content
  .order
  .order_data
  .order_data_list
  .order_data_item
  .order_data_item_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page_content
  .order
  .order_data
  .order_data_list
  .order_data_item
  .order_data_item_title
  svg {
  font-size: 22px;
  color: var(--blue);
  margin-right: 10px;
}

.page_content
  .order
  .order_data
  .order_data_list
  .order_data_item
  .order_data_item_title
  h4 {
  font-size: 20px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .order .order_data .order_data_list .order_data_item p {
  font-size: 16px;
  font-weight: normal;
  color: var(--blue);
  margin-top: 10px;
}

.page_content .order .order_data .order_value {
}

.page_content .order .order_data .order_value .order_value_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.page_content .order .order_data .order_value .order_value_title img {
  height: 60px;
  width: 60px;
}

.page_content .order .order_data .order_value .order_value_title h3 {
  font-size: 24px;
  font-weight: bold;
  color: var(--blue);
  margin-left: 10px;
}

.page_content .order .order_data .order_value .order_total_value {
  /* width: 100%; */
}

.page_content .order .order_data .order_value .order_total_value h1 {
  font-size: 28px;
  font-weight: bold;
  color: var(--red);
}

.page_content .order .order_data_2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
}

.page_content .order .order_data_2 .order_products {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.page_content .order .order_data_2 .order_products .order_products_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page_content .order .order_data_2 .order_products .order_products_title svg {
  font-size: 22px;
  color: var(--blue);
  margin-right: 10px;
}

.page_content .order .order_data_2 .order_products .order_products_title h4 {
  font-size: 20px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .order .order_data_2 .order_products .product {
  margin-top: 10px;
  margin-left: 10px;
  max-width: 400px;
  width: 100%;
  min-width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content .order .order_data_2 .order_products .product .product_img_data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content .order .order_data_2 .order_products .product .product_img {
  /* height: 40px;
  width: 40px;
  border-radius: 8px; */
  margin-right: 10px;
}

.page_content .order .order_data_2 .order_products .product .product_img img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
}

.page_content .order .order_data_2 .order_products .product .product_data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .order .order_data_2 .order_products .product .product_data h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--red);
}

.page_content .order .order_data_2 .order_products .product .product_data h4 {
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .order .order_data_2 .order_products .product .product_quant {
  font-size: 16px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .order .order_data_2 .order_status {
}

.page_content .order .order_data_2 .order_status .order_status_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page_content .order .order_data_2 .order_status .order_status_title svg {
  font-size: 22px;
  color: var(--blue);
  margin-right: 10px;
}

.page_content .order .order_data_2 .order_status .order_status_title h4 {
  font-size: 20px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .order .order_data_2 .order_status .order_status_status {
  font-size: 16px;
  font-weight: normal;
  color: var(--blue);
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.order_status_status label {
  margin-top: 10px;
  margin-left: 25px;
  cursor: pointer;
}

.order_status_status input[type="radio"] {
  display: none;
}

.order_status_status input[type="radio"] + label:before {
  border: 1px solid var(--blue);
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 10px 0 5px;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 50%;
}

.order_status_status input[type="radio"]:checked + label:before {
  background: var(--red);
  border: 1px solid var(--red);
  color: #fff;
  content: "\2022";
  font-size: 18px;
  line-height: 16px;
  text-align: center;
}

.order_status_status input[type="radio"]:checked + label:after {
  font-weight: bold;
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .head .search_bar {
  height: 40px;
  max-width: 1000px;
  width: 60%;
  min-width: 250px;
  border-radius: 8px;
  /* background: var(--light-gray); */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 5px;
  border: 2px solid var(--blue);
}

.page_content .head .search_bar input {
  border: none;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  /* background: var(--light-gray); */
  padding-left: 20px;
  padding-right: 5px;
  font-size: 14px;
}

.page_content .head .search_bar button {
  height: 100%;
  margin: 0 5px;
  line-height: 46px;
}

.page_content .head .search_bar button svg {
  font-size: 20px;
  color: var(--blue);
}

.page_content .head .search_bar button:hover svg {
  color: var(--red);
}

.page_content .product_list {
  width: 100%;
  /* margin-top: 80px; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  grid-gap: 30px;
}

.page_content .product_list .product_data {
  justify-self: center;
}

.page_content .product_list .product_data_content {
  width: 300px;
  margin: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid transparent;
}

.page_content .product_list .product_data_content:hover {
  border: 2px solid var(--red);
}

.page_content .product_list .product_data_content img {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  margin-right: 0px;
}

.page_content .product_list .product_data_content .text {
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.page_content .product_list .product_data_content .text div {
  height: auto;
  margin-bottom: 5px;
}

.page_content .product_list .product_data_content .text div h2 {
  font-size: 14px;
  font-weight: bold;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px;
  max-height: 48px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.page_content .product_list .product_data_content .text h3 {
  /* margin-top: 5px; */
  font-size: 24px;
  font-weight: bold;
  width: 140px;
}

.page_content .product_list .product_data_content .text p {
  font-size: 12px;
  /* margin-top: 5px; */
  margin-left: 5px;
  font-weight: bold;
  width: 100px;
}

.page_content .product_list .product_data_content .stars {
  height: 100px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .product_list .product_data_content .stars svg {
  font-size: 18px;
  color: var(--gray);
}

.page_content .product_list .product_data_content .stars .colorful {
  color: gold;
}

h2,
p {
  color: var(--blue);
}

h3 {
  color: var(--red);
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .product_imgs {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.page_content .product_imgs .product_img {
  position: relative;
  margin: 0 20px;
}

.page_content .product_imgs .product_img img {
  width: 160px;
  height: auto;
  border-radius: 8px;
}

.page_content .product_imgs .product_img .trash_icon {
  width: 40px;
  height: 40px;
  background: var(--blue);
  border: 2px solid var(--white);
  border-radius: 50%;
  position: absolute;
  bottom: -10px;
  right: -10px;
  font-size: 24px;
  color: var(--white);
  text-align: center;
  line-height: 44px;
}

.page_content .product_imgs .product_img .trash_icon:hover {
  background: var(--red);
}

.page_content .product_imgs .product_img input {
  display: none;
}

.page_content .product_imgs .product_img .img_add {
  width: 160px;
  height: 160px;
  border-radius: 8px;
  background: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_content .product_imgs .product_img .img_add:hover {
  cursor: pointer;
}

.page_content .product_imgs .product_img .img_add .img_add_icon {
  width: 50px;
  height: 50px;
  font-size: 36px;
  border-radius: 50%;
  color: var(--white);
  background: var(--blue);
  text-align: center;
  line-height: 62px;
}

.page_content .product_imgs .product_img .img_add:hover .img_add_icon {
  background: var(--red);
}

.page_content .product_details {
  min-width: 840px;
  width: 100%;
  max-width: 1200px;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
}

.page_content .product_details .product_more_details {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.page_content .product_details .product_description {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}

.page_content .product_details .text_input_container {
  position: relative;
  min-width: 400px;
  width: 100%;
  /* max-width: 500px; */
  padding: 12px 0 24px;
  margin: 0;
}

.page_content .product_details .text_input_container .text_input_title {
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 10px;
  padding: 2px 4px;
  background: var(--register-page-text-input-background);
  color: var(--register-page-text-input-label-color);
}

.page_content .product_details .text_input_container .text_input_content {
  height: 40px;
  width: 100%;
  border: 2px solid var(--register-page-text-input-border-color);
  padding: 0 10px 0 10px;
  border-radius: 8px;
}

.page_content .product_details .text_input_line {
  display: flex;
  justify-content: space-between;
  min-width: 400px;
  width: 100%;
  /* max-width: 500px; */
}

.page_content
  .product_details
  .text_input_line
  .text_input_container
  + .text_input_container {
  margin-left: 20px;
}

.page_content .product_buttons {
  min-width: 840px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.page_content .product_buttons .button1,
.page_content .product_buttons .button2 {
  margin-top: 40px;
  min-width: 400px;
  width: 50%;
  /* max-width: 450px; */
}

.page_content .product_buttons .button1 {
  margin-right: 20px;
}

.page_content .product_buttons .button2 {
  margin-left: 20px;
}

/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .product_imgs {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.page_content .product_imgs .product_img {
  position: relative;
  margin: 0 20px;
}

.page_content .product_imgs .product_img img {
  width: 160px;
  height: 160px;
  border-radius: 8px;
}

.page_content .product_imgs .product_img .trash_icon {
  width: 40px;
  height: 40px;
  background: var(--blue);
  border: 2px solid var(--white);
  border-radius: 50%;
  position: absolute;
  bottom: -10px;
  right: -10px;
  font-size: 24px;
  color: var(--white);
  text-align: center;
  line-height: 44px;
}

.page_content .product_imgs .product_img .trash_icon:hover {
  background: var(--red);
}

.page_content .product_imgs .product_img input {
  display: none;
}

.page_content .product_imgs .product_img .img_add {
  width: 160px;
  height: 160px;
  border-radius: 8px;
  background: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_content .product_imgs .product_img .img_add:hover {
  cursor: pointer;
}

.page_content .product_imgs .product_img .img_add .img_add_icon {
  width: 50px;
  height: 50px;
  font-size: 36px;
  border-radius: 50%;
  color: var(--white);
  background: var(--blue);
  text-align: center;
  line-height: 62px;
}

.page_content .product_imgs .product_img .img_add:hover .img_add_icon {
  background: var(--red);
}

.page_content .product_details {
  min-width: 840px;
  /* width: 100%; */
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
}

.page_content .product_details .product_more_details {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}

.page_content .product_details .product_description {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}

.page_content .product_details .text_input_container {
  position: relative;
  min-width: 400px;
  width: 100%;
  /* max-width: 500px; */
  padding: 12px 0 24px;
  margin: 0;
}

.page_content .product_details .text_input_container .text_input_title {
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 10px;
  padding: 2px 4px;
  background: var(--register-page-text-input-background);
  color: var(--register-page-text-input-label-color);
}

.page_content .product_details .text_input_container .text_input_content {
  height: 40px;
  width: 100%;
  border: 2px solid var(--register-page-text-input-border-color);
  padding: 0 10px 0 10px;
  border-radius: 8px;
}

.page_content .product_details .text_input_line {
  display: flex;
  justify-content: space-between;
  min-width: 400px;
  width: 100%;
  /* max-width: 500px; */
}

.page_content
  .product_details
  .text_input_line
  .text_input_container
  + .text_input_container {
  margin-left: 20px;
}

.page_content .product_buttons {
  min-width: 840px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.page_content .product_buttons .button1,
.page_content .product_buttons .button2 {
  margin-top: 40px;
  min-width: 400px;
  width: 50%;
  /* max-width: 450px; */
}

.page_content .product_buttons .button1 {
  margin-right: 20px;
}

.page_content .product_buttons .button2 {
  margin-left: 20px;
}

