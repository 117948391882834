.content {
  position: relative;
  min-width: 760px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--register-page-background);
}

.logo_container {
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: 40px;
}

.logo_container img {
  min-width: 200px;
  width: 70%;
  max-width: 300px;
  height: auto;
}

.logo_container img + img {
  margin-top: 0px;
}

.form_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  min-height: 600px;
  width: 100%;
  min-width: 600px;
  max-width: 1200px;
  background: var(--register-page-form-background);
  padding: 20px 6% 40px;
  /* border: 2px solid var(--register-page-form-border-color); */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.8);
  border-radius: 12px;
}

.form_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form_content h1 {
  margin: 10px 0 20px;
  color: var(--register-page-form-title-color);
}

.text_input_line {
  display: flex;
  justify-content: space-between;
  min-width: 300px;
  width: 100%;
  max-width: 600px;
}

.text_input_container {
  position: relative;
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  padding: 12px 0 24px;
  margin: 0;
}

.text_input_container .text_input_title {
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 10px;
  padding: 2px 4px;
  background: var(--register-page-text-input-background);
  color: var(--register-page-text-input-label-color);
}

.text_input_container .text_input_content {
  height: 40px;
  width: 100%;
  border: 2px solid var(--register-page-text-input-border-color);
  padding: 0 35px 0 10px;
  border-radius: 8px;
}

.button1,
.button2 {
  min-width: 300px;
  width: 100%;
  max-width: 600px;
}

.forget_password {
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: flex-start;
  padding-left: 10px;
}

.forget_password span {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: var(--red);
}

.user_type {
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
}

.user_type label {
  font-size: 16px;
  font-weight: bold;
  color: var(--blue);
  cursor: pointer;
}

.user_type .collaborator {
  margin-left: 20px;
}

.user_type {
  height: 40px;
  min-width: 300px;
  width: 100%;
  max-width: 600px;
  padding: 12px 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.user_type input[type="radio"] {
  display: none;
}

.user_type input[type="radio"] + label:before {
  border: 1px solid var(--blue);
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 10px 0 5px;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 50%;
}

.user_type input[type="radio"]:checked + label:before {
  background: var(--red);
  border: 1px solid var(--red);
  color: #fff;
  content: "\2022";
  font-size: 18px;
  line-height: 16px;
  text-align: center;
}

.user_type input[type="radio"]:checked + label:after {
  font-weight: bold;
}
