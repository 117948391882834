/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_content .head h1 {
  margin-right: 5px;
}

.page_content .register_form {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

.page_content .register_form .register_fields_container {
  width: 40%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.page_content .register_form .register_fields {
  width: 100%;
  max-width: 1000px;
  min-width: 400px;
}

.page_content .text_input_container {
  position: relative;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 12px 0 24px;
  margin: 0;
}

.page_content .text_input_container .text_input_title {
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 10px;
  padding: 2px 4px;
  background: var(--register-page-text-input-background);
  color: var(--register-page-text-input-label-color);
}

.page_content .text_input_container .text_input_content {
  height: 40px;
  width: 100%;
  border: 2px solid var(--register-page-text-input-border-color);
  padding: 0 10px;
  border-radius: 8px;
}

.page_content .text_input_line {
  display: flex;
  justify-content: space-between;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
}

.button1 {
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  margin-top: 80px;
}