/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .info_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40 40px;
  width: 90%;
  min-width: 240px;
  max-width: 720px;
  background: var(--register-page-form-background);
  border-radius: 12px;
  display: block;
}

h1 {
  margin: 20px 0 20px;
  color: var(--register-page-form-title-color);
}

.page_content .info_container.active {
  display: none;
}

.page_content .info_container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40 40px;
  width: 90%;
  min-width: 240px;
  max-width: 720px;
  background: var(--register-page-form-background);
  border-radius: 12px;
  display: block;
}

.edit_button {
  position: absolute;
  right: 5px;
  bottom: 5px;
}

.edit_icon {
  height: 20px;
  width: 20px;
  color: var(--edit-icon-color);
}
.edit_icon:hover {
  filter: brightness(80%);
}

.caret_down_button {
}

.caret_down_icon {
  height: 20px;
  width: 20px;
  color: var(--caret-down-icon-color);
}
.caret_down_icon:hover {
  filter: brightness(80%);
}

.adress_info_container .adress_list {
  display: block;
  transition: all 0.5s ease;
}
.adress_info_container .adress_list.active {
  display: none;
}
.adress_info_container .adress_list .adress_item {
  margin: 10px 0 0;
}
.adress_info_container .adress_list .adress_item .adress {
  width: 100%;
  border: none;
  margin: 10px 20px 0;
  padding: 10px 0 0;
}

.button2 {
  margin-top: 40px;
}

.button4 {
  max-width: 280px;
  min-width: 240px;
  margin-bottom: 20px;
}

.photo_container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.photo {
  width: 100%;
  max-width: 100px;
  height: auto;
  max-height: 100px;
  border: 2px solid var(--blue);
  padding: 2px;
  border-radius: 100%;
}

.photo_input {
  display: none;
}

.photo_input_label {
  margin-top: 20px;
  margin-bottom: 20px;
  max-width: 280px;
  min-width: 240px;
  width: 40%;
  height: 36px;
  border: none;
  border-radius: 12px;
  background: var(--button1-background);
  color: var(--button1-color);
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  line-height: 36px;
}
.photo_input_label:hover {
  filter: brightness(80%);
}

.form_container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40 40px;
  width: 100%;
  min-width: 240px;
  max-width: 720px;
  background: var(--register-page-form-background);
  border-radius: 12px;
}

.text_input_content {
  height: 55px;
}

.form_container3 {
  margin-top: 20px;
  width: 90%;
  min-width: 240px;
  max-width: 720px;
  display: none;
}

.form_container3.active {
  display: block;
}
