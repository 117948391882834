/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_content .head h1 {
  margin: 0;
  margin-right: 5px;
}

.page_content .profile_details {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 0 20px;
}

/* .page_content .profile_details .profile_data_container .profile_data {
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 13px 5px;
}

.page_content .profile_details .profile_data_container .profile_data h2 {
  font-size: 14px;
}

.page_content .profile_details .profile_data_container .profile_data p {
  font-size: 18px;
  padding: 5px;
} */

.page_content .profile_details .profile_data_container {
  position: relative;
  /* width: 600px; */
  padding: 0 20px 0 0;
}

.page_content .profile_details .profile_data_container .text_input_container {
  position: relative;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
  padding: 12px 0 24px;
  margin: 0;
}

.page_content
  .profile_details
  .profile_data_container
  .text_input_container
  svg {
  position: absolute;
  top: 22px;
  right: 10px;
  color: var(--red);
  height: 20px;
  width: 20px;
}

.page_content
  .profile_details
  .profile_data_container
  .text_input_container
  .text_input_title {
  font-size: 14px;
  position: absolute;
  top: 1px;
  left: 10px;
  padding: 2px 4px;
  background: var(--register-page-text-input-background);
  color: var(--register-page-text-input-label-color);
}

.page_content
  .profile_details
  .profile_data_container
  .text_input_container
  .text_input_content {
  height: 40px;
  width: 100%;
  border: 2px solid var(--register-page-text-input-border-color);
  padding: 0 35px 0 10px;
  border-radius: 8px;
}

.page_content .text_input_line {
  display: flex;
  justify-content: space-between;
  min-width: 400px;
  width: 100%;
  max-width: 1000px;
}

.page_content .profile_details .img_container {
  position: relative;
  width: 300px;
  height: 300px;
}

.page_content .profile_details .img_container img {
  width: 300px;
  height: 300px;
  border-radius: 40px;
}

.page_content .profile_details .img_container input {
  display: none;
}

.edit_icon_container {
  position: absolute;
  bottom: 0px;
  right: -10px;
  height: 40px;
  width: 40px;
  background: var(--blue);
  border: 2px solid var(--white);
  color: var(--white);
  font-size: 24px;
  text-align: center;
  padding: 6px 0;
  border-radius: 100px;
  cursor: pointer;
}

.edit_icon_container:hover {
  background: var(--red);
}

.page_content .profile_details .profile_data_container .button1 {
  width: 100%;
  min-width: 400px;
  margin-top: 80px;
}
