/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .product {
  position: relative;
  height: 100%;
  width: 100%;
  display: contents;
  /* flex-direction: column; */
}

.page_content .product .product_data {
  /* height: 400px; */
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.page_content .product .product_data .product_main_data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .product .product_data .product_main_data .product_stars {
  margin-bottom: 10px;
}

.page_content .product .product_data .product_main_data .product_stars svg {
  font-size: 40px;
  color: var(--gray);
}

.page_content
  .product
  .product_data
  .product_main_data
  .product_stars
  .product_stars_colorful {
  color: goldenrod;
}

.page_content .product .product_data .product_main_data .store_link {
  height: 20px;
  margin-top: 5px;
}

.page_content .product .product_data .product_main_data h4 {
  margin-left: 10px;
  color: var(--blue);
  font-size: 18px;
  font-weight: bold;
}

.page_content .product .product_data .product_main_data h3 {
  margin-left: 10px;
  color: var(--red);
  font-size: 48px;
  font-weight: bold;
}

.page_content
  .product
  .product_data
  .product_main_data
  .product_stars
  svg
  + svg {
  margin-left: 15px;
}

.page_content .product .product_data .product_main_data .button1 {
  margin-top: 20px;
  margin-bottom: 5px;
}

.page_content .product .product_data .product_img {
  /* min-width: 400px; */
  /* width: 100%; */
  /* max-width: 800px; */
  margin: 0;
  width: 600px;
  min-height: 378px;
  height: 100%;
  max-height: 400px;
}

.page_content .product .product_data .product_img .react-3d-carousel {
  width: 600px;
}

.page_content
  .product
  .product_data
  .product_img
  .react-3d-carousel
  .slider-single-content {
  box-shadow: 0 0 0 rgb(0 0 0 / 0%);
}

.page_content
  .product
  .product_data
  .product_img
  .react-3d-carousel
  .slider-right {
  left: unset;
  right: -14.25%;
  top: 10.5%;
  width: 14.75%;
  height: 79%;
  margin-left: 0px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.page_content
  .product
  .product_data
  .product_img
  .react-3d-carousel
  .slider-left {
  right: unset;
  left: -14.25%;
  top: 10.5%;
  width: 14.75%;
  height: 79%;
  margin-right: 0px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.page_content
  .product
  .product_data
  .product_img
  .react-3d-carousel
  .slider-right
  div,
.page_content
  .product
  .product_data
  .product_img
  .react-3d-carousel
  .slider-left
  div {
  border: none;
  right: 0%;
  left: 0%;
}

.page_content .product .product_data .product_img .react-3d-carousel img {
  min-width: 200px;
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 12px;
  border: 4px solid var(--white);
}

.page_content .product .product_description {
  width: 100%;
  margin-top: 80px;
  padding: 10px;
}

.page_content .product .product_description h4 {
  color: var(--red);
  font-size: 20px;
  font-weight: bold;
}

.page_content .product .product_description p {
  color: var(--blue);
  font-size: 14px;
  margin: 20px 10px 0 10px;
  text-align: justify;
}
