/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .cart_list {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content .cart_list .order {
  min-width: 640px;
  width: 100%;
  max-width: 1200px;
  border: 2px solid transparent;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.page_content .cart_list .order + .order {
  margin-top: 20px;
  border-top: 1px solid var(--light-gray);
}

.page_content .cart_list .order .order_products {
  width: 70%;
}

.page_content .cart_list .order .order_products p {
  font-size: 14px;
  color: var(--blue);
}

.page_content .cart_list .order .order_products .product {
  margin: 10px 0 30px 10px;
  max-width: 400px;
  width: 50%;
  height: auto;
  min-width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content .cart_list .order .order_products .product .product_img_data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content .cart_list .order .order_products .product .product_img {
  /* height: 40px;
  width: 40px;
  border-radius: 8px; */
  margin-right: 10px;
}

.page_content .cart_list .order .order_products .product .product_img img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
}

.page_content .cart_list .order .order_products .product .product_data {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .cart_list .order .order_products .product .product_data h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--red);
}

.page_content .cart_list .order .order_products .product .product_data h4 {
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .cart_list .order .order_products .product .product_quant {
  display: flex;
  flex-direction: row;
  width: 100px;
}

.page_content .cart_list .order .order_products .product .product_quant svg {
  font-size: 20px;
  color: var(--blue);
}

.page_content .cart_list .order .order_products .product .product_quant button {
  line-height: 10px;
  margin: 0 2px;
}

.page_content
  .cart_list
  .order
  .order_products
  .product
  .product_quant
  button:hover
  svg {
  color: var(--red);
}

.page_content .cart_list .order .order_products .product .product_quant input {
  border: none;
  /* background: var(--light-gray); */
  border: 2px solid var(--blue);
  border-radius: 4px;
  width: 40px;
  height: 30px;
  font-size: 14px;
  color: var(--blue);
  padding: 0 6px;
  text-align: center;
}

.page_content .cart_list .order .order_data {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  padding-left: 20px;
  border: none;
}

.page_content .cart_list .order .order_products .order_products_store_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page_content
  .cart_list
  .order
  .order_products
  .order_products_store_title
  svg {
  font-size: 16px;
  color: var(--blue);
}

.page_content .cart_list .order .order_products .order_products_store_title h4 {
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .cart_list .order .order_data .order_value {
  position: relative;
  margin-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content .cart_list .order .order_data .order_value .order_value_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content
  .cart_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img {
  /* height: 10px;
  width: 10px; */
}

.page_content
  .cart_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img
  img {
  height: 40px;
  width: 40px;
}

.page_content .cart_list .order .order_data .order_value .order_value_title h4 {
  margin-left: 5px;
  font-size: 24px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .cart_list .order .order_data .order_value .order_total_value {
  margin-top: 5px;
  font-size: 36px;
  font-weight: bold;
  color: var(--red);
}

.page_content .cart_list .order .order_data .order_value .button1 {
  min-width: 200px;
  width: 100%;
  margin-top: 40px;
}
