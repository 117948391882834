/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 0 80px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

/* .page_content .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.page_content .head h1 {
  margin: 0;
  margin-right: 5px;
}

.page_content .head img {
  height: 150px;
  width: auto;
} */

.page_content .store_list {
  width: 100%;
  /* margin-top: 80px; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  grid-gap: 30px;
}

.page_content .store_list .store_data {
  justify-self: center;
}

.page_content .store_list .store_data_content {
  width: 300px;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid transparent;
}

.page_content .store_list .store_data_content:hover {
  border: 2px solid var(--red);
}

.page_content .store_list .store_data_content img {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  margin-right: 10px;
}

.page_content .store_list .store_data_content div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .store_list .store_data_content div h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.page_content .store_list .store_data_content div div {
  height: 40px;
}

.page_content .store_list .store_data_content div div p {
  font-size: 14px;
  margin-left: 5px;
}

h2,
p {
  color: var(--blue);
}
