/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .cart_list {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content .cart_list .order {
  min-width: 640px;
  width: 100%;
  max-width: 1200px;
  border: 2px solid transparent;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.page_content .cart_list .order + .order {
  margin-top: 20px;
  border-top: 1px solid var(--light-gray);
}

.page_content .cart_list .order .order_addresses {
  width: 70%;
}

.page_content .cart_list .order .order_addresses h3 {
  font-size: 20px;
  margin: 0 0 30px 15px;
}

.page_content .cart_list .order .order_data .order_value {
  position: relative;
  margin-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content .cart_list .order .order_data .order_value .order_value_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content
  .cart_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img {
  /* height: 10px;
  width: 10px; */
}

.page_content
  .cart_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img
  img {
  height: 40px;
  width: 40px;
}

.page_content .cart_list .order .order_data .order_value .order_value_title h4 {
  margin-left: 5px;
  font-size: 24px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .cart_list .order .order_data .order_value .order_total_value {
  margin-top: 5px;
  font-size: 36px;
  font-weight: bold;
  color: var(--red);
}

.page_content .cart_list .order .order_data .order_value .button1 {
  min-width: 200px;
  width: 100%;
  margin-top: 40px;
}

.page_content .cart_list .order .order_addresses .address {
  margin: 10px 0 30px 10px;
  max-width: 600px;
  width: 100%;
  height: auto;
  min-width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.address label {
  font-size: 16px;
  font-weight: bold;
  color: var(--blue);
  cursor: pointer;
}

.address input[type="radio"] {
  display: none;
}

.address input[type="radio"] + label:before {
  border: 1px solid var(--blue);
  content: "\00a0";
  display: inline-block;
  font: 16px/1em sans-serif;
  height: 16px;
  margin: 0 10px 0 5px;
  padding: 0;
  vertical-align: top;
  width: 16px;
  border-radius: 50%;
}

.address input[type="radio"]:checked + label:before {
  background: var(--red);
  border: 1px solid var(--red);
  color: #fff;
  content: "\2022";
  font-size: 18px;
  line-height: 16px;
  text-align: center;
}

.address input[type="radio"]:checked + label:after {
  font-weight: bold;
}
