.page_content .collabs_list {
  width: 100%;
  display: grid;
  grid-gap: 25px;
}

.page_content .collabs_list .collab_data_container {
  justify-self: center;
}

.page_content .collabs_list .collab_data_container .collab_data {
  position: relative;
  width: 800px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 12px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border: 2px solid transparent;
}

.page_content .collabs_list .collab_data_container .collab_data:hover {
  border: 2px solid var(--red);
}

.page_content .collabs_list .collab_data_container .collab_data .collab_main {
  width: 100px;
  height: 100px;
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_main
  img {
  width: 100px;
  height: 100px;
  margin-right: 10px;
  border-radius: 10px;
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_details {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 10px;
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_details
  h2 {
  font-size: 18px;
  font-weight: bold;
  color: var(--red);
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_details
  h3 {
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
  margin-top: 5px;
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_details
  .data_group {
  margin-top: 25px;
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_details
  .data_group
  p {
  display: inline-block;
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_details
  .data_group
  p
  + p {
  margin-left: 5px;
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_delete_icon_button {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 30px;
  color: var(--blue);
  font-size: 24px;
}

.page_content
  .collabs_list
  .collab_data_container
  .collab_data
  .collab_delete_icon_button:hover {
  opacity: 0.8;
}

p {
  color: var(--blue);
}
