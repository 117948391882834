/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content .top_bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  border-bottom: 1px solid var(--gray);
  padding: 0 0 2px 2px;
}

.page_content .top_bar .top_bar_button {
  font-size: 18px;
  color: var(--blue);
}

.page_content .top_bar .top_bar_button + .top_bar_button {
  margin-left: 40px;
}

.page_content .top_bar .top_bar_button.active {
  font-weight: bold;
  color: var(--red);
}

.page_content .orders_list {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content .orders_list .order {
  min-width: 640px;
  width: 100%;
  max-width: 1200px;
  border-radius: 12px;
  border: 2px solid transparent;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.page_content .orders_list .order + .order {
  margin-top: 20px;
}

.page_content .orders_list .order:hover {
  border: 2px solid var(--red);
}

.page_content .orders_list .order .order_products {
  width: 45%;
}

.page_content .orders_list .order .order_products p {
  font-size: 14px;
  color: var(--blue);
}

.page_content .orders_list .order .order_products .product {
  margin-top: 10px;
  margin-left: 10px;
  max-width: 400px;
  width: 100%;
  height: auto;
  min-width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.page_content .orders_list .order .order_products .product .product_img_data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content .orders_list .order .order_products .product .product_img {
  /* height: 40px;
  width: 40px;
  border-radius: 8px; */
  margin-right: 10px;
}

.page_content .orders_list .order .order_products .product .product_img img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
}

.page_content .orders_list .order .order_products .product .product_data {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .orders_list .order .order_products .product .product_data h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--red);
}

.page_content .orders_list .order .order_products .product .product_data h4 {
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .orders_list .order .order_products .product .product_quant {
  font-size: 16px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .orders_list .order .order_data {
  width: 55%;
  /* height: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-left: 20px;
  border-left: 1px solid var(--gray);
}

.page_content .orders_list .order .order_data .order_data_container {
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content {
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  + .order_data_content {
  margin-top: 20px;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  .order_data_content_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  .order_data_content_title
  svg {
  font-size: 16px;
  color: var(--blue);
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  .order_data_content_title
  h4 {
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content
  .orders_list
  .order
  .order_data
  .order_data_container
  .order_data_content
  p {
  font-size: 14px;
  color: var(--blue);
  margin-top: 4px;
  margin-left: 10px;
}

.page_content .orders_list .order .order_data .order_value {
  margin-left: 10px;
}

.page_content .orders_list .order .order_data .order_value .order_value_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img {
  /* height: 10px;
  width: 10px; */
}

.page_content
  .orders_list
  .order
  .order_data
  .order_value
  .order_value_title
  .order_value_title_img
  img {
  height: 30px;
  width: 30px;
}

.page_content
  .orders_list
  .order
  .order_data
  .order_value
  .order_value_title
  h4 {
  margin-left: 5px;
  font-size: 18px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .orders_list .order .order_data .order_value .order_total_value {
  margin-top: 5px;
  font-size: 28px;
  font-weight: bold;
  color: var(--red);
}
