@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  --green: #0d7356;
  --blue: #052440;
  --red: #bf1717;
  --orange: #f27830;
  --beige: #f2e0d0;
  --gray: #a7a7a7;
  --light-gray: #ccc;
  --white: #fff;
  --black: #000;

  --sidebar-background: var(--blue);
  --sidebar-color: var(--white);
  --sidebar-hover-background: var(--red);
  --sidebar-hover-color: var(--white);
  --sidebar-search-background: var(--white);
  --sidebar-search-color: var(--blue);
  --sidebar-active-search-btn-hover-color: var(--red);
  --sidebar-caption-background: var(--red);
  --sidebar-caption-color: var(--white);
  --sidebar-current-page-color: var(--red);

  --register-page-background: var(--blue);
  --register-page-form-background: var(--white);
  --register-page-form-border-color: var(--red);
  --register-page-form-title-color: var(--red);
  --register-page-text-input-background: var(--white);
  --register-page-text-input-label-color: var(--blue);
  --register-page-text-input-border-color: var(--blue);

  --button1-background: var(--red);
  --button1-color: var(--white);

  --button2-background: var(--blue);
  --button2-color: var(--white);

  --button3-background: var(--black);
  --button3-color: var(--blue);
  --button3-color-hover: var(--red);

  --text-info-background-color: var(--white);
  --text-info-label-color: var(--red);
  --text-info-border-color: var(--blue);

  --edit-icon-color: var(--red);
  --caret-down-icon-color: var(--red);

  --search-input-background: var(--light-gray);

  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

body {
  font: 400 14px Roboto, sans-serif;
  background-color: var(--white);
  -webkit-font-smoothing: antialiased;
}

input,
button,
textarea {
  font: 400 18px Roboto, sans-serif;
}

button {
  background: none;
  cursor: pointer;
  border: none;
}

.button1 {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 12px;
  background: var(--button1-background);
  color: var(--button1-color);
  font-size: 18px;
  text-align: center;
  padding: 10px;
}
.button1:hover {
  filter: brightness(80%);
}

.button2 {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 12px;
  background: var(--button2-background);
  color: var(--button2-color);
  font-size: 18px;
  text-align: center;
  padding: 10px;
}
.button2:hover {
  filter: brightness(80%);
}

.button3 {
  margin-top: 20px;
  width: 100%;
  /* height: 40px; */
  border: none;
  /* border-radius: 12px; */
  /* background: var(--button3-background); */
  color: var(--button3-color);
  font-size: 18px;
  text-align: center;
  padding: 10px;
  font-weight: bold;
}
.button3:hover {
  color: var(--button3-color-hover);
}

.page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 0 80px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.page_content .head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.page_content .head h1 {
  color: var(--red);
}

.page_content .head img {
  height: 100px;
  width: auto;
  margin-left: 20px;
}

.circle_button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 100px;
  height: 48px;
  width: 48px;
  background: var(--red);
  padding: 6px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 1);
}

.circle_button:hover {
  /* filter: brightness(80%); */
}
.circle_button:hover .caption {
  /* filter: brightness(125%); */
}

.circle_button svg {
  height: 36px;
  width: 36px;
  color: var(--white);
  text-align: center;
}

.circle_button .caption {
  position: absolute;
  left: -275px;
  bottom: 0;
  transform: translateY(-50%);
  border-radius: 6px;
  height: 30px;
  line-height: 30px;
  width: 250px;
  background: var(--sidebar-caption-background);
  color: var(--sidebar-caption-color);
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0s;
  opacity: 0;
  pointer-events: none;
  display: block;
}
.circle_button:hover .caption {
  transition: all 0.5s ease;
  opacity: 1;
  top: 50%;
}
