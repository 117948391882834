/* .page_content {
  position: absolute;
  width: calc(100% - 80px);
  left: 80px;
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
} */

.page_content {
  /* width: 100%; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_content .order {
  width: 100%;
  max-width: 1200px;
  min-width: 700px;
}

.page_content .order .order_data {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.page_content .order .order_data .order_data_list {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, minmax(300px, auto));
}

.page_content .order .order_data .order_data_list .order_data_item {
}

.page_content
  .order
  .order_data
  .order_data_list
  .order_data_item
  .order_data_item_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page_content
  .order
  .order_data
  .order_data_list
  .order_data_item
  .order_data_item_title
  svg {
  font-size: 22px;
  color: var(--blue);
  margin-right: 10px;
}

.page_content
  .order
  .order_data
  .order_data_list
  .order_data_item
  .order_data_item_title
  h4 {
  font-size: 20px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .order .order_data .order_data_list .order_data_item p {
  font-size: 16px;
  font-weight: normal;
  color: var(--blue);
  margin-top: 10px;
}

.page_content .order .order_data .order_value {
}

.page_content .order .order_data .order_value .order_value_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.page_content .order .order_data .order_value .order_value_title img {
  height: 60px;
  width: 60px;
}

.page_content .order .order_data .order_value .order_value_title h3 {
  font-size: 24px;
  font-weight: bold;
  color: var(--blue);
  margin-left: 10px;
}

.page_content .order .order_data .order_value .order_total_value {
  /* width: 100%; */
}

.page_content .order .order_data .order_value .order_total_value h1 {
  font-size: 28px;
  font-weight: bold;
  color: var(--red);
}

.page_content .order .order_data_2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 40px;
}

.page_content .order .order_data_2 .order_products {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.page_content .order .order_data_2 .order_products .order_products_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page_content .order .order_data_2 .order_products .order_products_title svg {
  font-size: 22px;
  color: var(--blue);
  margin-right: 10px;
}

.page_content .order .order_data_2 .order_products .order_products_title h4 {
  font-size: 20px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .order .order_data_2 .order_products .product {
  margin-top: 10px;
  margin-left: 10px;
  max-width: 400px;
  width: 100%;
  min-width: 240px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content .order .order_data_2 .order_products .product .product_img_data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.page_content .order .order_data_2 .order_products .product .product_img {
  /* height: 40px;
  width: 40px;
  border-radius: 8px; */
  margin-right: 10px;
}

.page_content .order .order_data_2 .order_products .product .product_img img {
  height: 40px;
  width: 40px;
  border-radius: 6px;
}

.page_content .order .order_data_2 .order_products .product .product_data {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.page_content .order .order_data_2 .order_products .product .product_data h3 {
  font-size: 20px;
  font-weight: bold;
  color: var(--red);
}

.page_content .order .order_data_2 .order_products .product .product_data h4 {
  font-size: 14px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .order .order_data_2 .order_products .product .product_quant {
  font-size: 16px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .order .order_data_2 .order_status {
}

.page_content .order .order_data_2 .order_status .order_status_title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.page_content .order .order_data_2 .order_status .order_status_title svg {
  font-size: 22px;
  color: var(--blue);
  margin-right: 10px;
}

.page_content .order .order_data_2 .order_status .order_status_title h4 {
  font-size: 20px;
  font-weight: bold;
  color: var(--blue);
}

.page_content .order .order_data_2 .order_status .order_status_status {
  font-size: 16px;
  font-weight: normal;
  color: var(--blue);
  margin-top: 10px;
}
